import { footer_EN_trans } from "../Component/Footer/Footer_trans";
import { Navbar_EN_Trans } from "../Component/Navbar/Nav_Trans";
import { Login_EN_Trans } from "../Pages/Auth/Login/Login_trans";
import { Register_EN_Trans } from "../Pages/Auth/Register/Register_trans";
import { BecomeAPartner_EN_Trans } from "../Pages/BecomeAPartner/BecomeAPartner_Trans";
import { About_En_trans } from "../Pages/HomePage/HomeTrans/About_trans";
import { CatService_EN_trans } from "../Pages/HomePage/HomeTrans/CatService_trans";
import { Eventandcat_EN_trans } from "../Pages/HomePage/HomeTrans/EvenetAndcat_trans";
import { Explore_En_trans } from "../Pages/HomePage/HomeTrans/Explore_trans";
import { Home_EN_Trans } from "../Pages/HomePage/HomeTrans/Home_Trans";
import { Menu_En_trans } from "../Pages/HomePage/HomeTrans/Menu_trans";
import { Search_En_trans } from "../Pages/HomePage/HomeTrans/Search_Trans";
import { MyBooking_EN_trans } from "../Pages/User/UserTrans/MyBooking_trans";
import { MyProfile_EN_trans } from "../Pages/User/UserTrans/MyProfile_trans";

export const English = {
  translation: {
    Navbar: Navbar_EN_Trans,
    section1: Home_EN_Trans,
    search: Search_En_trans,
    explore: Explore_En_trans,
    Register: Register_EN_Trans,
    Login: Login_EN_Trans,
    Menu: Menu_En_trans,
    About: About_En_trans,
    catSer: CatService_EN_trans,
    eAndC: Eventandcat_EN_trans,
    becomePartner: BecomeAPartner_EN_Trans,
    myProfile: MyProfile_EN_trans,
    myBooking: MyBooking_EN_trans,
    footer:footer_EN_trans,
  },
};
