import {
  Box,
  Typography,
  IconButton,
  TextField,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import upload from "../../Assets/images/drag.svg";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import DeleteIcon from "@mui/icons-material/Delete";

export default function MultiImageUpload({
  setImages,
  error,
  helperText,
  title,
  name,
  apiPreview,
  imgTitle,
  max, // Max limit of images that can be uploaded
}) {
  const [previews, setPreviews] = useState([]);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [titles, setTitles] = useState({});
  const { t } = useTranslation();
  const theme = useTheme();

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
    },
    onDrop: (acceptedFiles) => {
      // Check if adding new images exceeds the limit
      if (uploadedImages.length + acceptedFiles.length > max)
      {
        toast.error(`You can only upload a maximum of ${max} images.`);
        return; // Prevent additional uploads
      }

      const validFiles = acceptedFiles.filter(
        (file) => file.size <= 5 * 1024 * 1024
      );

      if (validFiles.length !== acceptedFiles.length)
      {
        toast.error(" Some files were too large. Max size is 5MB.");
      }

      const newPreviews = validFiles.map((file, index) => {
        const url = URL.createObjectURL(file);
        return { url, file, title: "" };
      });

      setPreviews((prev) => [...prev, ...newPreviews]);
      setUploadedImages((prev) => [...prev, ...validFiles]);

      if (setImages)
      {
        setImages((prev) => [...prev, ...validFiles]);
      }
    },
  });

  const handleRemoveImage = (index) => {
    const updatedPreviews = previews.filter((_, i) => i !== index);
    const updatedImages = uploadedImages.filter((_, i) => i !== index);
    const updatedTitles = { ...titles };
    delete updatedTitles[index];

    setPreviews(updatedPreviews);
    setUploadedImages(updatedImages);
    setTitles(updatedTitles);

    if (setImages)
    {
      setImages(updatedImages);
    }
  };

  const handleTitleChange = (e, index) => {
    const { value } = e.target;
    setTitles((prevTitles) => ({
      ...prevTitles,
      [index]: value,
    }));
    setPreviews((prevPreviews) =>
      prevPreviews.map((preview, i) =>
        i === index ? { ...preview, title: value } : preview
      )
    );
  };

  const isValid = () => {
    if (imgTitle)
    {
      return previews.every((preview) => preview.title.trim() !== "");
    }
    return true;
  };

  return (
    <div>
      <Box
        p={2}
        m={"auto"}
        width={"100%"}
        maxWidth={"540px"}
        border={error ? "1px dashed #d32f2f" : "1px dashed #F57C00"}
        borderRadius="10px"
        sx={{
          background: `#FFFAF5`,
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
          }}
          {...getRootProps()}
        >
          {/* Disable input if limit reached */}
          <input {...getInputProps()} multiple disabled={uploadedImages.length >= max} />
          <figure>
            <img
              src={upload}
              alt="upload-placeholder"
              width="100px"
              height="100px"
              draggable="false"
              style={{ objectFit: "contain", borderRadius: "10px" }}
            />
          </figure>
          <Typography variant="body1" textAlign="center">
            {t("becomePartner.step2.foodTruckDetails.image.drag")}
          </Typography>
        </div>
      </Box>

      {/* Display Previews */}
      {previews.length > 0 && (
        <Box
          mt={2}
          display="flex"
          flexWrap="wrap"
          gap={2}
          justifyContent={"center"}
        >
          {previews.map((preview, index) => (
            <Box key={index} width="100px" height="150px" position="relative">
              <img
                src={preview.url}
                alt={`preview-${index}`}
                style={{
                  width: "100%",
                  height: "90px",
                  objectFit: "contain",
                  borderRadius: "10px",
                }}
              />
              {imgTitle && (
                <TextField
                  value={titles[index] || ""}
                  onChange={(e) => handleTitleChange(e, index)}
                  placeholder={t("becomePartner.step4.certi.pl")}
                  variant="outlined"
                  size="small"
                  sx={{
                    width: "100%",
                    mt: 1,
                    input: {
                      padding: "8px",
                      fontSize: "12px",
                      color: theme.palette.text.subHead,
                    },

                  }}
                  error={!titles[index]?.trim()}
                  helperText={
                    !titles[index]?.trim() &&
                    `${t("becomePartner.step4.certi.Required")}`
                  }
                />
              )}

              {/* Delete Icon */}
              <IconButton
                onClick={() => handleRemoveImage(index)}
                sx={{
                  position: "absolute",
                  top: 0,
                  right: "-10px",
                  color: "white",
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.7)" },
                }}
              >
                <DeleteIcon sx={{ fontSize: "15px" }} />
              </IconButton>
            </Box>
          ))}
        </Box>
      )}

      {/* Display Error Message */}
      <Typography ml={2} color="#d32f2f" fontSize="0.75rem">
        {helperText}
      </Typography>
    </div>
  );
}
