export const MyProfile_EN_trans = {
  title: "My Profile",
  logout: "Log Out",
  reset:"Reset Password",
  update:'Update'
};

export const MyProfile_NL_trans = {
  title: "Mijn Profiel",
  logout: "Uitloggen",
  reset: "Wachtwoord Resetten",
  update: "Bijwerken"
};
