export const Register_EN_Trans = {
  register: "Register",
  email: "Enter Email",
  password: "Enter Password",
  repssword: "Re-Enter your password",
  understand: "I Understand the",
  terms: "Terms of Service",
  and: "and",
  privacy: " Privacy Policy.",
  already: "Already have an account?",
  fullname: "Enter your full name",
  number: "Enter your number",
  selectcountry: "Select your country",
  loginhere: "Login here",
};

export const Register_DE_Trans = {
  register: "Registrieren",
  email: "E-Mail eingeben",
  password: "Passwort eingeben",
  repssword: "Passwort erneut eingeben",
  understand: "Ich verstehe die",
  terms: "Nutzungsbedingungen",
  and: "und",
  privacy: "Datenschutzbestimmungen.",
  already: "Haben Sie bereits ein Konto?",
  fullname: "Geben Sie Ihren vollständigen Namen ein",
  number: "Geben Sie Ihre Telefonnummer ein",
  selectcountry: "Wählen Sie Ihr Land aus",
  loginhere: "Hier einloggen",
};
