export const API_endpoints = {
  baseURL: "http://api.fiestacatering.de/",
  login: "/api/auth/login",
  register: "/api/auth/signup",
  forgot:"/api/auth/reset-password",
  countries: "/api/auth/countries",
  usergetbyid: "/api/v1/user/get-by-id",
  updateuser:"/api/v1/user/update",
  addcompany:"/api/v1/partner/company/add",
  updatecompany:"/api/v1/partner/company/update",
};
