import React, { useState } from "react";
import {
  Box,
  Checkbox,
  FormControl,
  InputLabel,
  FormHelperText,
  FormControlLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import ButtonComponent from "../../Component/Button/Button";
import BookingCard from "./BookingCard";
const MyBookings = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [selected, setSelected] = useState(1);

  const handleButtonClick = (value) => {
    setSelected(value); // Update the selected state
  };

  return (
    <Box sx={{ width: "90%", m: "auto", maxWidth: '1400px' }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          justifyContent: "space-between",
          py: { xs: 1, md: 5 },
          gap: 1,
        }}
      >
        <Typography
          className="fontSnugle"
          sx={{
            textAlign: "center",
            fontSize: { xs: "20px", sm: "24px", md: "30px" },
          }}
        >
          {`${t("myBooking.title")}`}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: 1,
          m: "auto",
          pt: 1,
          width: { xs: "100%", md: "60%" },
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ButtonComponent
          type="button"
          fontfamily={"Nunito Sans"}
          onClick={() => handleButtonClick(1)} // Set selected to 1 for 'All'
          label={`${t("myBooking.all")}`}
          sx={{
            width: { xs: "80%", sm: "20%" },
            fontWeight:"700",
            border:
              selected === 1
                ? `1px solid ${theme.palette.text.active}`
                : "none",
            background:
              selected === 1 ? theme.palette.background.main : "transparent",
            color:
              selected === 1
                ? theme.palette.text.active
                : theme.palette.text.defaultDark,
          }}
        />
        <ButtonComponent
          type="button"
          fontfamily={"Nunito Sans"}
          onClick={() => handleButtonClick(2)} // Set selected to 2 for 'Pending'
          label={`${t("myBooking.pending")}`}
          sx={{
            width: { xs: "80%", sm: "20%" },
            fontWeight: "700",
            border:
              selected === 2
                ? `1px solid ${theme.palette.text.active}`
                : "none",
            background:
              selected === 2 ? theme.palette.background.main : "transparent",
            color:
              selected === 2
                ? theme.palette.text.active
                : theme.palette.text.defaultDark,
          }}
        />
        <ButtonComponent
          type="button"
          fontfamily={"Nunito Sans"}
          onClick={() => handleButtonClick(3)} // Set selected to 3 for 'Cancelled'
          label={`${t("myBooking.cancelled")}`}
          sx={{
            width: { xs: "80%", sm: "20%" },
            fontWeight: "700",
            border:
              selected === 3
                ? `1px solid ${theme.palette.text.active}`
                : "none",
            background:
              selected === 3 ? theme.palette.background.main : "transparent",
            color:
              selected === 3
                ? theme.palette.text.active
                : theme.palette.text.defaultDark,
          }}
        />
      </Box>
      <Box sx={{ py: 5, display: "flex", flexDirection: "column", gap: 1 }}>
        <BookingCard />
        <BookingCard />
        <BookingCard />
        <BookingCard />
      </Box>
    </Box>
  );
};

export default MyBookings;
