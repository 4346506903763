export const Menu_En_trans = {
  menutitle: "Choose Food Truck From Menu",
  subtitle:
    "It is a long established fact that a reader will be distracted by the readable",
  burgermenu: "Burger Truck Menu",
  pizzamenu: "Pizza Truck Menu",
  icecreammenu: "Ice-Cream Truck Menu",
  bbqmenu: "BBQ Truck Menu",
};


export const Menu_NL_trans = {
  menutitle: "Kies een Food Truck van het Menu",
  subtitle:
    "Het is een algemeen bekend feit dat een lezer zal worden afgeleid door de leesbare inhoud",
  burgermenu: "Burger Truck Menu",
  pizzamenu: "Pizza Truck Menu",
  icecreammenu: "IJs Truck Menu",
  bbqmenu: "BBQ Truck Menu",
};
