import axios from "axios";
import { API_endpoints } from "./API_Endpoints";

const Axios = axios.create({
  baseURL: API_endpoints.baseURL,
});

export const API_POST = async (url, data) => {
  let apikey;
  let token;
  let parsedData = JSON.parse(localStorage.getItem("Fiesta"));
  if (parsedData) {
    apikey = parsedData?.apikey;
    token = parsedData?.token;
  }
  let uri;
  if (token && apikey) {
    uri = url + "?token=" + token + "&apikey=" + apikey;
  } else {
    uri = url;
  }
  try {
    const res = await Axios.post(uri, data, {
      headers: null,
    });

    return res.data;
  } catch (error) {
    return error;
  }
};

export const API_GET = async (url) => {
  try {
    const token = localStorage.getItem("token");
    const apikey = localStorage.getItem("apikey");

    let uri;
    if (token && apikey) {
      uri = url + "?token=" + token + "&apikey=" + apikey;
    } else {
      uri = url;
    }

    const { data } = await Axios.get(uri, {
      headers: null,
    });

    return data;
  } catch (error) {
    return error;
  }
};
export const API_GET_USERID = async (url, id) => {
  try {
    const token = JSON.parse(localStorage.getItem("Fiesta")).token;
    const apikey = JSON.parse(localStorage.getItem("Fiesta")).apikey;

    const { data } = await Axios.get(url, {
      params: {
        user_id: id,
        token: token,
        apikey: apikey,
      },
      headers: {
        // Add any headers you need here
      },
    });

    return data;
  } catch (error) {
    return error;
  }
};
export const API_GET_ID = async (url, id) => {
  try {
    const token = JSON.parse(localStorage.getItem("Fiesta")).token;
    const apikey = JSON.parse(localStorage.getItem("Fiesta")).apikey;

    const { data } = await Axios.get(url, {
      params: {
        id: id,
        token: token,
        apikey: apikey,
      },
      headers: {
        // Add any headers you need here
      },
    });

    return data;
  } catch (error) {
    return error;
  }
};
