import React from "react";
import {
  Box,
  Checkbox,
  FormControl,
  InputLabel,
  FormHelperText,
  FormControlLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import booking_img from "../../Assets/images/cate1.png";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ButtonComponent from "../../Component/Button/Button";
const BookingCard = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        bgcolor: theme.palette.background.main,
        borderRadius: "18px",
        p: 3,
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        justifyContent: "space-between",
        gap: { xs: 0.5, md: 2 },
        height: { xs: "", md: "180px" },
        width: { xs: "100%", sm: "80%", md: "100%" },
        m: "auto",
      }}
    >
      <Box
        sx={{
          width: { xs: "100%", sm: "80%", md: "100%" },
          m: "auto",
          height: { xs: "120px", sm: "180px", md: "100%" },
        }}
      >
        <Box sx={{ height: "100%" }}>
          <img
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              borderRadius: "8px",
            }}
            src={booking_img}
            alt="preview"
          />
        </Box>
      </Box>
      <Box
        sx={{
          border: {
            xs: "none",
            md: `1.5px dashed ${theme.palette.text.active}`,
          },
        }}
      ></Box>
      <Box
        sx={{
          width: "100%",
          height: { xs: "50px", md: "100%" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "row", md: "column" },
            alignItems: "center",
            height: "100%",
            p: 1,
            py: { xs: 0, md: 4 },
            gap: 1,
            justifyContent: { xs: "space-between", md: "center" },
          }}
        >
          <Typography>Food Truck Name</Typography>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: { xs: "14px", sm: "16px", md: "18px" },
            }}
          >
            Ace Food Truck
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          border: {
            xs: "none",
            md: `1.5px dashed ${theme.palette.text.active}`,
          },
        }}
      ></Box>
      <Box
        sx={{
          width: "100%",
          height: { xs: "auto", md: "100%" },
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: { xs: "auto", md: "100%" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "row", md: "column" },
              alignItems: "center",
              height: { xs: "auto", md: "100%" },
              p: 1,
              py: { xs: 0, md: 4 },
              justifyContent: "space-between",
            }}
          >
            <Typography>Start Date</Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                bgcolor: theme.palette.background.lightorange,
                p: { xs: 1, md: 1 },
                borderRadius: "8px",
                width: "fit-content",
              }}
            >
              <CalendarMonthIcon sx={{ color: theme.palette.text.active }} />
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: { xs: "13px", sm: "14px", md: "16px" },
                }}
              >
                24-08-2024
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          border: {
            xs: "none",
            md: `1.5px dashed ${theme.palette.text.active}`,
          },
        }}
      ></Box>
      <Box
        sx={{
          width: "100%",
          height: { xs: "auto", md: "100%" },
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: { xs: "auto", md: "100%" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "row", md: "column" },
              alignItems: "center",
              height: { xs: "auto", md: "100%" },
              p: 1,
              py: { xs: 0, md: 4 },
              justifyContent: "space-between",
            }}
          >
            <Typography>End Date</Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                bgcolor: theme.palette.background.lightorange,
                p: { xs: 1, md: 1 },
                borderRadius: "8px",
                width: "fit-content",
              }}
            >
              <CalendarMonthIcon sx={{ color: theme.palette.text.active }} />
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: { xs: "13px", sm: "14px", md: "16px" },
                }}
              >
                31-08-2024
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          border: {
            xs: "none",
            md: `1.5px dashed ${theme.palette.text.active}`,
          },
        }}
      ></Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          height: { xs: "50px", md: "100%" },
          justifyContent: "center",
          alignItems: "center",
          mt: { xs: 1, md: 0 },
        }}
      >
        <ButtonComponent
          label={"Cancel"}
          sx={{ border: "solid #FF00006B", color: "#FF00006B" }}
        />
      </Box>
    </Box>
  );
};

export default BookingCard;
