import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ children }) => {
  const [id, setUserID] = useState();

  const redirectPath = children?.props?.pathname;


  const storedData = localStorage.getItem("Fiesta");

  if (storedData) {
    const { token } = JSON.parse(storedData);
    if (token) {
      return children;
    }
  }

  return <Navigate to="/login" state={{ path: redirectPath }} />;
};

export default PrivateRoute;
