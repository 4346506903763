export const LightPallete = {
  background: {
    main: "#FFFFFF",
    btn: "#164FA1",
    card: "#EFEFEF",
    label: "#164FA124",
    strip: "#1A130C",
    dark: "#1A130C",
    inputBg: "#FFF3E8",
    orange: "#F57C00",
    section: "#F5F5F5",
    lightorange: "#FFEDDD",
  },

  text: {
    main: "#1A130C",
    active: "#F57C00",
    blue: "#164FA1",
    defaultLight: "#FFFFFF",
    defaultDark: "#000000",
    subHead: "#625C56",
    list: "#1A130C",
    greyText: "#FFFFFFB2",
    primary: "#fff",
    secondary: "#231B12B2",
    time: "#41C47D",
    select_input: "#1A130C99",
    error: "#f44336",
    darkOrange: "#823D0C",
  },
};
