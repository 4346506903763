import React, { useState } from "react";
import { Box, TextField, useTheme, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { StarSVG } from "../../../Component/SVG";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import styled from "@emotion/styled/macro";
// import SingleImage from "../../../Component/ImageUploader/SingleImage";
import DragandDrop from "../../../Component/ImageUploader/DragandDrop";

const Step2 = () => {
    const theme = useTheme();
    const [currentType, setcurrentType] = useState(1);
    const { t } = useTranslation();

    const initial1 = {
        type: 1,
        about_yourSelf: "",
        truck_size: 0,
        electricy_requirement: "",
        truck_mode: "",
        water_Connection: "",
    };
    const initial2 = {
        type: 1,
        about_yourSelf: "",
        image: "",
        truck_name: "",
        truck_address: "",
        truck_category: "",
        truck_specification: "",
        zip_code: 0,
        area: "",
        start_time: "",
        end_time: "",
        description: "",
        truck_size: 0,
        electricy_requirement: "",
        truck_mode: "",
        water_Connection: "",
    };

    const validationSchema1 = Yup.object({
        type: Yup.number().required("Required"),
        about_yourSelf: Yup.string().required("Required"),
        truck_size: Yup.number()
            .required("Required")
            .min(0, "Truck size must be a positive number"),
        electricy_requirement: Yup.string().required("Required"),
        truck_mode: Yup.string().required("Required"),
        water_Connection: Yup.string().required("Required"),
    });

    // Validation schema for `initial2`
    const validationSchema2 = Yup.object({
        type: Yup.number().required("Required"),
        about_yourSelf: Yup.string().required("Required"),
        image: Yup.string().required("Required"),
        truck_name: Yup.string().required("Required"),
        truck_address: Yup.string().required("Required"),
        truck_category: Yup.string().required("Required"),
        truck_specification: Yup.string().required("Required"),
        zip_code: Yup.number()
            .required("Required")
            .min(0, "Zip code must be a positive number"),
        state: Yup.string().required("Required"),
        area: Yup.string().required("Required"),
        start_time: Yup.string().required("Required"),
        end_time: Yup.string().required("Required"),
        description: Yup.string().required("Required"),
        truck_size: Yup.number()
            .required("Required")
            .min(0, "Truck size must be a positive number"),
        electricy_requirement: Yup.string().required("Required"),
        truck_mode: Yup.string().required("Required"),
        water_Connection: Yup.string().required("Required"),
    });

    // Determine which schema to use based on `currentType`
    const validationSchema =
        currentType === 2 ? validationSchema2 : validationSchema1;

    const formik = useFormik({
        initialValues: currentType === 2 ? initial2 : initial1,
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            console.log(values);
            //   try {
            //     const res = await API_POST(API_endpoints.login, values);
            //     if (res.s) {
            //       toast.success(res.m);
            //       navigate("/");
            //     } else {
            //       toast.error(res.m);
            //     }
            //   } catch (error) {
            //     console.error("Error:", error);
            //   }
        },
    });

    function handleTypeChange(value) {
        setcurrentType(value);
    }

    const InputWrapper = styled("div")(({ theme }) => ({
        width: "100%",
        padding: "16px",
        borderRadius: "12px",
        border:
            formik.touched.dob && formik.errors.dob
                ? "1px solid red"
                : "1px solid #1A130C3D",
        display: "flex",
        alignItems: "center",
        "&:hover": {
            border:
                formik.touched.dob && formik.errors.dob ? "1px solid red" : `1px solid ${theme.palette.background.lightorange}`,
            backgroundColor: theme.palette.background.inputBg,
        },
        '& input[type="time"]': {
            width: "100%",
            border: "none",
            backgroundColor: "transparent",
            color: theme.palette.text.defaultDark,
            padding: "0",
            margin: "0",
            "&:focus": {
                outline: "none",
            },
        },
    }));
    return (
        <Box>
            <Box
                sx={{
                    fontSize: { xs: "25px", fontWeight: "600", padding: "1rem" },
                }}
            >
                {t("becomePartner.step2.title")}
            </Box>
            <Box
                sx={{
                    background: theme.palette.background.main,
                    borderRadius: "25px",
                    padding: 3,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    gap: 2,
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: { xs: "column", sm: "row" },
                        gap: { xs: 2, sm: 3 },
                    }}
                >
                    <Box sx={{ width: "100%" }}>
                        <StarSVG /> {t("becomePartner.step2.types.title")}
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            name="type"
                            id="type"
                            value={formik.values.type}
                            onChange={(e) => {
                                handleTypeChange(e.target.value);
                                formik.setFieldValue("type", e.target.value)
                            }}
                        >
                            <FormControlLabel
                                value={1}
                                control={<Radio />}
                                label={`${t("becomePartner.step2.types.type1")}`}
                            />
                            <FormControlLabel
                                value={2}
                                control={<Radio />}
                                label={`${t("becomePartner.step2.types.type2")}`}
                            />
                            <FormControlLabel
                                value={3}
                                control={<Radio />}
                                label={`${t("becomePartner.step2.types.type3")}`}
                            />
                            <FormControlLabel
                                value={4}
                                control={<Radio />}
                                label={`${t("becomePartner.step2.types.type4")}`}
                            />
                        </RadioGroup>
                    </Box>
                    <Box sx={{ width: "100%" }}>
                        <Box py={1}>
                            <StarSVG /> {t("becomePartner.step2.yourSelf.title")}
                        </Box>

                        <TextField
                            type="text"
                            id="about_yourSelf"
                            name="about_yourSelf"
                            onChange={formik.handleChange}
                            onBlur={formik.values.about_yourSelf}
                            value={formik.values.about_yourSelf}
                            error={
                                formik.touched.about_yourSelf &&
                                Boolean(formik.errors.about_yourSelf)
                            }
                            helperText={
                                formik.touched.about_yourSelf && formik.errors.about_yourSelf
                            }
                            variant="outlined"
                            placeholder={t("becomePartner.step2.yourSelf.pl")}
                            multiline
                            fullWidth
                            rows={5}
                            InputProps={{
                                sx: {
                                    borderRadius: "12px",
                                    color: theme.palette.text.defaultDark,
                                    "&:hover": {
                                        color: theme.palette.text.active,
                                        bgcolor: theme.palette.background.inputBg,
                                        borderRadius: "12px",
                                    },
                                },
                            }}
                        />
                    </Box>
                </Box>

                <Box sx={{ display: currentType == 2 ? "block" : "none" }}>
                    <Box>
                        <Typography
                            sx={{
                                fontWeight: "700",
                                fontSize: "24px",
                                color: theme.palette.text.active,
                            }}
                        >
                            {t("becomePartner.step2.foodTruckDetails.title")}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: { xs: "column", sm: "row" },
                            gap: { xs: 2, sm: 3 },
                        }}
                    >
                        <Box sx={{ width: "100%" }}>
                            <Box py={1}>
                                <StarSVG />
                                {t("becomePartner.step2.foodTruckDetails.image.title")}
                            </Box>

                            <DragandDrop />
                        </Box>
                        <Box
                            sx={{
                                width: "100%",
                                display: "flex",
                                gap: { xs: 2, sm: 2 },
                                flexDirection: "column",
                            }}
                        >
                            <Box sx={{ width: "100%" }}>
                                <Box py={1}>
                                    <StarSVG />{" "}
                                    {t("becomePartner.step2.foodTruckDetails.truckName.title")}
                                </Box>

                                <TextField
                                    type="text"
                                    id="truck_name"
                                    name="truck_name"
                                    fullWidth
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.truck_name}
                                    error={
                                        formik.touched.truck_name && Boolean(formik.errors.truck_name)
                                    }
                                    helperText={
                                        formik.touched.truck_name && formik.errors.truck_name
                                    }
                                    variant="outlined"
                                    placeholder={t(
                                        "becomePartner.step2.foodTruckDetails.truckName.pl"
                                    )}
                                    InputProps={{
                                        sx: {
                                            borderRadius: "12px",
                                            color: theme.palette.text.defaultDark,
                                            "&:hover": {
                                                color: theme.palette.text.active,
                                                bgcolor: theme.palette.background.inputBg,
                                                borderRadius: "12px",
                                            },
                                        },
                                    }}
                                />
                            </Box>
                            <Box sx={{ width: "100%" }}>
                                <Box py={1}>
                                    <StarSVG />
                                    {t("becomePartner.step2.foodTruckDetails.address.title")}
                                </Box>

                                <TextField
                                    type="text"
                                    id="truck_address"
                                    name="truck_address"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.truck_address}
                                    error={
                                        formik.touched.truck_address &&
                                        Boolean(formik.errors.truck_address)
                                    }
                                    helperText={
                                        formik.touched.truck_address && formik.errors.truck_address
                                    }
                                    variant="outlined"
                                    placeholder={t(
                                        "becomePartner.step2.foodTruckDetails.address.pl"
                                    )}
                                    multiline
                                    fullWidth
                                    rows={4}
                                    InputProps={{
                                        sx: {
                                            borderRadius: "12px",
                                            color: theme.palette.text.defaultDark,
                                            "&:hover": {
                                                color: theme.palette.text.active,
                                                bgcolor: theme.palette.background.inputBg,
                                                borderRadius: "12px",
                                            },
                                        },
                                    }}
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: { xs: "column", sm: "row" },
                            gap: { xs: 2, sm: 3 },
                        }}
                    >
                        <Box sx={{ width: "100%" }}>
                            <Box py={1}>
                                <StarSVG />{" "}
                                {t("becomePartner.step2.foodTruckDetails.category.title")}</Box>
                            <TextField
                                type="text"
                                id="truck_category"
                                name="truck_category"
                                fullWidth
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.truck_category}
                                error={
                                    formik.touched.truck_category &&
                                    Boolean(formik.errors.truck_category)
                                }
                                helperText={
                                    formik.touched.truck_category && formik.errors.truck_category
                                }
                                variant="outlined"
                                placeholder={t(
                                    "becomePartner.step2.foodTruckDetails.category.pl"
                                )}
                                InputProps={{
                                    sx: {
                                        borderRadius: "12px",
                                        color: theme.palette.text.defaultDark,
                                        "&:hover": {
                                            color: theme.palette.text.active,
                                            bgcolor: theme.palette.background.inputBg,
                                            borderRadius: "12px",
                                        },
                                    },
                                }}
                            />
                        </Box>
                        <Box sx={{ width: "100%" }}>
                            <Box py={1}>
                                <StarSVG />{" "}
                                {t("becomePartner.step2.foodTruckDetails.specification.title")}</Box>
                            <TextField
                                type="text"
                                id="truck_category"
                                name="truck_category"
                                fullWidth
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.truck_category}
                                error={
                                    formik.touched.truck_category &&
                                    Boolean(formik.errors.truck_category)
                                }
                                helperText={
                                    formik.touched.truck_category && formik.errors.truck_category
                                }
                                variant="outlined"
                                placeholder={t(
                                    "becomePartner.step2.foodTruckDetails.specification.pl"
                                )}
                                InputProps={{
                                    sx: {
                                        borderRadius: "12px",
                                        color: theme.palette.text.defaultDark,
                                        "&:hover": {
                                            color: theme.palette.text.active,
                                            bgcolor: theme.palette.background.inputBg,
                                            borderRadius: "12px",
                                        },
                                    },
                                }}
                            />
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: { xs: "column", sm: "row" },
                            gap: { xs: 2, sm: 3 },
                        }}
                    >
                        <Box sx={{ width: "100%" }}>
                            <Box py={1}>
                                <StarSVG />{" "}
                                {t("becomePartner.step2.foodTruckDetails.zipCode.title")}</Box>
                            <TextField
                                type="text"
                                id="truck_category"
                                name="truck_category"
                                fullWidth
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.truck_category}
                                error={
                                    formik.touched.truck_category &&
                                    Boolean(formik.errors.truck_category)
                                }
                                helperText={
                                    formik.touched.truck_category && formik.errors.truck_category
                                }
                                variant="outlined"
                                placeholder={t("becomePartner.step2.foodTruckDetails.zipCode.pl")}
                                InputProps={{
                                    sx: {
                                        borderRadius: "12px",
                                        color: theme.palette.text.defaultDark,
                                        "&:hover": {
                                            color: theme.palette.text.active,
                                            bgcolor: theme.palette.background.inputBg,
                                            borderRadius: "12px",
                                        },
                                    },
                                }}
                            />
                        </Box>
                        {/* <Box sx={{ width: "100%" }}>
                            <Box py={1}>
                                <StarSVG /> {t("becomePartner.step2.foodTruckDetails.state.title")}</Box>
                            <TextField
                                type="text"
                                id="state"
                                name="state"
                                fullWidth
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.state}
                                error={formik.touched.state && Boolean(formik.errors.state)}
                                helperText={formik.touched.state && formik.errors.state}
                                variant="outlined"
                                placeholder={t("becomePartner.step2.foodTruckDetails.state.pl")}
                                InputProps={{
                                    sx: {
                                        borderRadius: "12px",
                                        color: theme.palette.text.defaultDark,
                                        "&:hover": {
                                            color: theme.palette.text.active,
                                            bgcolor: theme.palette.background.inputBg,
                                            borderRadius: "12px",
                                        },
                                    },
                                }}
                            />
                        </Box> */}
                        <Box sx={{ width: "100%" }}>
                            <Box py={1}>
                                <StarSVG /> {t("becomePartner.step2.foodTruckDetails.area.title")}</Box>
                            <TextField
                                type="text"
                                id="area"
                                name="area"
                                fullWidth
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.area}
                                error={formik.touched.area && Boolean(formik.errors.area)}
                                helperText={formik.touched.area && formik.errors.area}
                                variant="outlined"
                                placeholder={t("becomePartner.step2.foodTruckDetails.area.pl")}
                                InputProps={{
                                    sx: {
                                        borderRadius: "12px",
                                        color: theme.palette.text.defaultDark,
                                        "&:hover": {
                                            color: theme.palette.text.active,
                                            bgcolor: theme.palette.background.inputBg,
                                            borderRadius: "12px",
                                        },
                                    },
                                }}
                            />
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: { xs: "column", sm: "row" },
                            gap: { xs: 2, sm: 3 },
                        }}
                    >

                        <Box sx={{ width: "100%" }}>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: { xs: "row", sm: "row" },
                                    gap: { xs: 2, sm: 3 },
                                }}
                            >
                                <Box sx={{ width: "100%" }}>
                                    <Box py={1} sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                                        <StarSVG />{" "}
                                        <Typography noWrap>
                                            {t("becomePartner.step2.foodTruckDetails.time.title")}</Typography>
                                    </Box>
                                    <InputWrapper>
                                        <input
                                            type="time"
                                            value={formik.values.start_time}
                                            onChange={(e) =>
                                                formik.setFieldValue("start_time", e.target.value)
                                            }
                                            onBlur={formik.handleBlur}
                                            style={{
                                                border:
                                                    formik.touched.start_time && formik.errors.start_time
                                                        ? "1px solid red"
                                                        : "",
                                            }}
                                        />
                                    </InputWrapper>{" "}
                                </Box>
                                <Box
                                    sx={{
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "end",
                                    }}
                                >
                                    {/* <StarSVG /> {t("becomePartner.step2.foodTruckDetails.state.title")} */}
                                    <InputWrapper>
                                        <input
                                            type="time"
                                            value={formik.values.end_time}
                                            onChange={(e) =>
                                                formik.setFieldValue("end_time", e.target.value)
                                            }
                                            onBlur={formik.handleBlur}
                                            style={{
                                                border:
                                                    formik.touched.end_time && formik.errors.end_time
                                                        ? "1px solid red"
                                                        : "",
                                            }}
                                        />
                                    </InputWrapper>{" "}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{ width: "100%" }}>
                        <Box py={1}>
                            <StarSVG /> {t("becomePartner.step2.foodTruckDetails.desc.title")}
                        </Box>

                        <TextField
                            type="text"
                            id="description"
                            name="description"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.description}
                            error={
                                formik.touched.description && Boolean(formik.errors.description)
                            }
                            helperText={formik.touched.description && formik.errors.description}
                            variant="outlined"
                            placeholder={t("becomePartner.step2.foodTruckDetails.desc.pl")}
                            multiline
                            fullWidth
                            rows={4}
                            InputProps={{
                                sx: {
                                    borderRadius: "12px",
                                    color: theme.palette.text.defaultDark,
                                    "&:hover": {
                                        color: theme.palette.text.active,
                                        bgcolor: theme.palette.background.inputBg,
                                        borderRadius: "12px",
                                    },
                                },
                            }}
                        />
                    </Box>
                    <Box>
                        <Typography
                            sx={{
                                fontWeight: "700",
                                fontSize: "24px",
                                color: theme.palette.text.active, mt: 2
                            }}
                        >
                            {t("becomePartner.step2.questions.title")}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: { xs: "column", sm: "row" },
                            gap: { xs: 2, sm: 3 },
                        }}
                    >
                        <Box sx={{ width: "100%" }}>
                            <Box py={1}>
                                <StarSVG /> {t("becomePartner.step2.questions.truckSize.title")}</Box>
                            <TextField
                                type="text"
                                id="truck_size"
                                name="truck_size"
                                fullWidth
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.truck_size}
                                error={
                                    formik.touched.truck_size && Boolean(formik.errors.truck_size)
                                }
                                helperText={formik.touched.truck_size && formik.errors.truck_size}
                                variant="outlined"
                                placeholder={t("becomePartner.step2.questions.truckSize.pl")}
                                InputProps={{
                                    sx: {
                                        borderRadius: "12px",
                                        color: theme.palette.text.defaultDark,
                                        "&:hover": {
                                            color: theme.palette.text.active,
                                            bgcolor: theme.palette.background.inputBg,
                                            borderRadius: "12px",
                                        },
                                    },
                                }}
                            />
                        </Box>
                        <Box sx={{ width: "100%" }}>
                            <Box py={1}>
                                <StarSVG /> {t("becomePartner.step2.questions.electricity.title")}</Box>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                // defaultValue="female"
                                row
                                value={formik.values.electricy_requirement}
                                name="radio-buttons-group"
                            >
                                <FormControlLabel value="1" control={<Radio />} label={"16A"} />
                                <FormControlLabel value="2" control={<Radio />} label={`32A`} />
                                <FormControlLabel value="3" control={<Radio />} label={`230V`} />
                                <FormControlLabel
                                    value="4"
                                    control={<Radio />}
                                    label={`${t(
                                        "becomePartner.step2.questions.electricity.other"
                                    )}`}
                                />
                            </RadioGroup>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: { xs: "column", sm: "row" },
                            gap: { xs: 2, sm: 3 },
                        }}
                    >
                        <Box sx={{ width: "100%" }}>
                            <Box py={1}>
                                <StarSVG /> {t("becomePartner.step2.questions.operatingMode.title")} </Box>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                // defaultValue="female"
                                row
                                value={formik.values.truck_mode}
                                // onChange={}
                                name="truck_mode"
                            >
                                <FormControlLabel
                                    value="1"
                                    control={<Radio />}
                                    label={`${t(
                                        "becomePartner.step2.questions.operatingMode.op1"
                                    )}`}
                                />
                                <FormControlLabel
                                    value="2"
                                    control={<Radio />}
                                    label={`${t(
                                        "becomePartner.step2.questions.operatingMode.op2"
                                    )}`}
                                />
                            </RadioGroup>
                        </Box>
                        <Box sx={{ width: "100%" }}>
                            <Box py={1}>
                                <StarSVG /> {t("becomePartner.step2.questions.water.title")} </Box>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                // defaultValue="female"
                                row
                                value={formik.values.water_Connection}
                                onChange={formik.handleChange}
                                name="water_Connection"
                            >
                                <FormControlLabel
                                    value={1}
                                    control={<Radio />}
                                    label={`${t("becomePartner.step2.questions.water.op1")}`}
                                />
                                <FormControlLabel
                                    value={0}
                                    control={<Radio />}
                                    label={`${t("becomePartner.step2.questions.water.op2")}`}
                                />
                            </RadioGroup>
                        </Box>
                    </Box></Box>
            </Box>
        </Box>
    );
};

export default Step2;
