import React from "react";
import HomeSection from "./Sections/HomeSection";
import SearchSection from "./Sections/SearchSection";
import ExploreSection from "./Sections/ExploreSection";
import CateringSection from "./Sections/CateringSection";
import MenuSection from "./Sections/MenuSection";
import AboutSection from "./Sections/AboutSection";
import CateringServiceSection from "./Sections/CateringServicesSection";
import EventandcatSection from "./Sections/EventandcatSection";

const HomePage = () => {
  return (
    <>
      <HomeSection />
      <SearchSection />
      <ExploreSection />
      <CateringSection />
      <CateringServiceSection />
      <MenuSection />
      <AboutSection />
      <EventandcatSection />
    </>
  );
};

export default HomePage;
