import { Button } from "@mui/material";
import React from "react";

const ButtonComponent = ({ label, type, sx, onClick, height, fontfamily }) => {
  return (
    <Button
      className={fontfamily || "fontSnugle"}
      onClick={onClick}
      type={type || "button"}
      sx={{
        ...sx,
        borderRadius: "40px",
        // width: "130px",
        height: "48px",
        padding: "4px 38px",
        textTransform: "none",
        fontSize: "18px",
      }}
    >
      {label}
    </Button>
  );
};

export default ButtonComponent;
