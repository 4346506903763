import { Box, Typography, useTheme, Grid } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import Catering from "../../../Assets/images/cate1.png";
import Cat1 from "../../../Assets/images/cat2.jpg";
import Cat2 from "../../../Assets/images/cat3.jpg";
import bg from "../../../Assets/images/orange.webp";
import CatServiceCard from "./Cards/CatServiceCard";
import ScrollAnimation from 'react-animate-on-scroll';
// import "animate.css/animate.min.css";
const cateringData = [
  {
    id: 1,
    title: "Corporate Catering",
    desc: "Providing professional catering services for corporate events with a variety of menu options.",
    image: Catering,
  },
  {
    id: 2,
    title: "Wedding Catering",
    desc: "Elegant catering services for weddings with customizable menus and themes to suit your special day.",
    image: Cat1,
  },
  {
    id: 3,
    title: "Private Catering",
    desc: "Exclusive catering for private parties with personalized food and drink menus to make your event memorable.",
    image: Cat2,
  },
];

const CateringServiceSection = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        width: "100%",
        background: `url(${bg}) center center / cover`,
        position: "relative",
        flexDirection: "column",
        paddingY: { xs: 2, md: 5 },
      }}
    >
      <Box
        sx={{
          width: { xs: "100%", sm: "80%", md: "50%", lg: "35%", xl: "30%" },
          p: 1,
          m: "auto",
        }}
      >
        <Typography
          variant="h3"
          className="fontSnugle"
          sx={{
            color: theme.palette.text.dark,
            fontSize: { xs: "28px", sm: "30px", md: "37px", xl: "40px" },
            textAlign: "center",
          }}
        >
          {t("catSer.title")}
        </Typography>
        <Typography
          variant="h6"
          // className="fontSnugle"
          sx={{
            color: theme.palette.text.subHead,
            fontSize: { xs: "18px", md: "20px" },
            textAlign: "center",
            mt: "0.5rem",
          }}
        >
          {t("catSer.subtitle")}
        </Typography>
      </Box>

      {/* <Box sx={{ width: "90%", margin: "auto" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: { xs: "space-between", sm: 'center' },
            alignItems: "center",
            flexDirection: "row",
            flexWrap: "wrap",
            gap: 2,
            width: "100%",
            pb: 2,
          }}
        >

          {cateringData.map((x, i) => (
            <Box sx={{ width: { xs: '100%', sm: '100%', md: '40%',lg:'30%' }, m: 'auto',}}>
              <ScrollAnimation
                animateIn="animate__flipInX"
                delay={i * 300}
                key={i}
              >
                <Box sx={{ width: { xs: '100%', sm: "100%", md: '100%' }, m: 'auto' }}>
                  <CatServiceCard data={x} /></Box>
              </ScrollAnimation></Box>
          ))}

        </Box>

        <Box className="flexCenter" sx={{ p: 1 }}>
          <span
            style={{
              fontWeight: "600",
              color: theme.palette.text.active,
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            {" "}
            {t("catSer.btn")}
          </span>
        </Box>
      </Box> */}
      <Grid container spacing={3} sx={{ width: "90%", margin: "auto", pb: 2 }}>
        {/* Catering Data Section */}
        <Grid
          item
          container
          xs={12}
          justifyContent={{ xs: "space-between", sm: "center" }}
          alignItems="center"
          spacing={2}
          className="removeDefault"
        >
          {cateringData.map((x, i) => (
            <Grid item xs={12} sm={6} md={5} lg={4} xl={3} key={i}>
              <ScrollAnimation animateIn="animate__flipInX" delay={i * 300}>
                <Box sx={{ width: "100%", m: "auto" }}>
                  <CatServiceCard data={x} />
                </Box>
              </ScrollAnimation>
            </Grid>
          ))}
        </Grid>

        {/* Button Section */}
        <Grid item xs={12} className="flexCenter" sx={{ p: 1 }}>
          <span
            style={{
              fontWeight: "600",
              color: theme.palette.text.active,
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            {t("catSer.btn")}
          </span>
        </Grid>
      </Grid>

    </Box>
  );
};

export default CateringServiceSection;
