import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';

const BecomeAPartnerStepper = ({ activeStep, totalSteps }) => {
    const theme = useTheme();

    // Styles for the states
    const getStepStyle = (index) => {
        if (index < activeStep)
        {
            return {
                background: theme.palette.text.active, // Color for completed
                color: "white",
            };
        } else if (index === activeStep)
        {
            return {
                background: "#FFFAF5", // Active step background
                color: "#000",
                border: `1px solid ${theme.palette.text.active}`,
            };
        } else
        {
            return {
                background: "#ccc", // Pending steps
                color: "#666",
            };
        }
    };

    return (
        <Box
            display="flex"
            justifyContent={{ xs: "center", sm: "start" }}
            gap={2}
            paddingY={3}
            sx={{
                overflowX: "auto",  // Allows scrolling horizontally on mobile
                paddingX: { xs: 2, sm: 3 },  // Padding adjustments
            }}
        >
            {Array.from({ length: totalSteps }).map((_, index) => (
                <Box
                    key={index}
                    display="flex"
                    alignItems="start"
                    flexDirection="column"
                    sx={{
                        flexShrink: 0,  // Prevents steps from shrinking on mobile
                    }}
                >
                    {/* Circle */}
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",

                            gap: { xs: 2, md: 1 },  
                        }}
                    >
                        <Box
                            sx={{
                                borderRadius: "50%",
                                padding: { xs: "10px", md: "15px" }, 
                                width: { xs: "30px", md: "40px" },   
                                height: { xs: "30px", md: "40px" },  
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                ...getStepStyle(index), 
                            }}
                        >
                            {index < activeStep ? <CheckRoundedIcon style={{ color: theme.palette.background.main }} /> : <Typography variant="body1" className="fontSnugle" sx={{ fontWeight: '600', textAlign: "center" }}>
                                {index + 1}
                            </Typography>}
                        </Box>
                        {/* Line between steps */}
                        {index < totalSteps - 1 && (
                            <Box
                                sx={{
                                    display: { xs: 'none', sm: index > 3 ? "none" : "flex" },
                                    background:
                                        index < activeStep ? theme.palette.text.active : "#ccc",
                                    width: { sm: "50px", md: "100px" }, // Responsive width
                                    height: "5px",
                                    borderRadius: "10px",
                                }}
                            ></Box>
                        )}
                    </Box>
                </Box>
            ))}
        </Box>
    );
};

export default BecomeAPartnerStepper;
