import { Box, Typography } from "@mui/material";
import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const CustomPhoneNumber = ({
  label,
  placeholder,
  name,
  value,
  errors,
  onChange,
  isLoading,
  country,
  theme,
}) => {
  return (
    <Box sx={{ width: "100%" }}>
      {/* <Typography
        sx={{
          color: "#126892",
          fontWeight: "400",
          fontSize: "18px",
          textAlign: "left",
          fontSize: { xs: "16px", sm: "18px" },
          fontFamily: "Sarabun",
          // mb: "10px",
        }}
      >
        {label}
      </Typography> */}

      <PhoneInput
        style={{
          // border: `1px solid red !important`,
          outline: "none !important",
          color: theme.palette.text.defaultDark,
        }}
        className=""
        name={name}
        country={country}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        inputClass="inputClassCountry"
      />

      {errors ? (
        <Typography
          sx={{
            color: "red",
            fontWeight: "400",
            fontSize: { xs: "14px", sm: "14px" },
            marginTop: "5px",
          }}
        >
          {errors}
        </Typography>
      ) : null}
    </Box>
  );
};

export default CustomPhoneNumber;
