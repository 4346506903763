import { footer_NL_trans } from "../Component/Footer/Footer_trans";
import { Navbar_NL_Trans } from "../Component/Navbar/Nav_Trans";
import { Login_NL_Trans } from "../Pages/Auth/Login/Login_trans";
import { Register_DE_Trans } from "../Pages/Auth/Register/Register_trans";
import { BecomeAPartner_NL_Trans } from "../Pages/BecomeAPartner/BecomeAPartner_Trans";
import { About_NL_trans } from "../Pages/HomePage/HomeTrans/About_trans";
import { CatService_NL_trans } from "../Pages/HomePage/HomeTrans/CatService_trans";
import { Eventandcat_NL_trans } from "../Pages/HomePage/HomeTrans/EvenetAndcat_trans";
import { Explore_Nl_trans } from "../Pages/HomePage/HomeTrans/Explore_trans";
import { Home_NL_Trans } from "../Pages/HomePage/HomeTrans/Home_Trans";
import { Search_Nl_trans } from "../Pages/HomePage/HomeTrans/Search_Trans";
import { MyBooking_NL_trans } from "../Pages/User/UserTrans/MyBooking_trans";
import { MyProfile_NL_trans } from "../Pages/User/UserTrans/MyProfile_trans";

export const German = {
  translation: {
    Login: Login_NL_Trans,
    Register: Register_DE_Trans,
    Navbar: Navbar_NL_Trans,
    section1: Home_NL_Trans,
    search: Search_Nl_trans,
    explore: Explore_Nl_trans,
    catSer: CatService_NL_trans,
    eAndC: Eventandcat_NL_trans,
    becomePartner: BecomeAPartner_NL_Trans,
    myProfile: MyProfile_NL_trans,
    myBooking: MyBooking_NL_trans,
    footer:footer_NL_trans,
    About:About_NL_trans  
  },
};
