import * as React from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { toast } from "react-toastify";
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import ButtonComponent from "../../../Component/Button/Button";
import { API_endpoints } from "../../../API/API_Endpoints";
import { API_POST } from "../../../API/config";


export default function ForgotPasswordDialogue() {
    const [open, setOpen] = React.useState(false);
    const [emailError, setEmailError] = React.useState("");

    const theme = useTheme();
    const { t } = useTranslation();
    const [email, setEmail] = React.useState("");

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleSubmit = async () => {
        try
        {
            if (email === "")
            {
                setEmailError("Please enter email.");
                return;
            } else if (!/^[\w\\.-]+@[a-zA-Z\d\\.-]+\.[a-zA-Z]{2,}$/.test(email))
            {
                setEmailError("Please enter a valid email.");
                return;
            } else
            {
                setEmailError("");
                let f = new FormData();
                f.append("email", email);
                let res = await API_POST(API_endpoints.forgot, f)
                if (res.s)
                {
                    toast.success(res.m);
                    setOpen(false)
                    setEmail("")
                } else
                {
                    toast.error(res.m)
                }
            }
        } catch (error)
        {
            console.log(error);
        }
    };

    const handleCancel = () => {
        setOpen(false)
        setEmail("")
    }

    return (
        <React.Fragment>
            {/* <span variant="outlined" >
                {t("signin.login.forgot.mainTitle")}
            </span> */}
            <Typography onClick={handleClickOpen}
                sx={{
                    color: theme.palette.text.active,
                    fontSize: { xs: "14px", md: "14px" },
                    textDecoration: "underline",
                    cursor: 'pointer'
                }}
            >
                {t("Login.forgot")}
            </Typography>
            <Dialog
                open={open}
                onClose={handleClose}
                sx={{
                    backdropFilter: "blur(5px)",
                    // color: theme.palette.dark.main,
                }}
                PaperProps={{
                    sx: { borderRadius: 5 },
                }}
            >
                <Box
                    sx={{
                        padding: "1rem",
                        color: theme.palette.text.defaultDark,
                    }}
                    component="form"
                >
                    <DialogTitle>{t("Login.forgot")}</DialogTitle>
                    <DialogContent sx={{ padding: "1rem 1.5rem 0.5rem 1.5rem " }}>
                        <DialogContentText>
                            {t("Login.forgotnote")}
                        </DialogContentText>
                        <TextField
                            fullWidth
                            autoComplete="false"
                            onChange={(e) => {
                                setEmail(e.target.value);
                                setEmailError(""); // Clear error on input change
                            }}
                            type="email"
                            id="email"
                            name="email"
                            autoFocus
                            required
                            margin="dense"
                            variant="outlined"
                            placeholder={t("Login.email")}
                            error={Boolean(emailError)} // Boolean to determine error state
                            helperText={emailError} // Display error message
                            InputProps={{
                                sx: {
                                    borderRadius: "12px",
                                    color: theme.palette.text.defaultDark,
                                    "&:hover": {
                                        color: theme.palette.text.active,
                                        bgcolor: theme.palette.background.inputBg,
                                        borderRadius: "12px",
                                    },
                                },
                            }}
                        />

                    </DialogContent>
                    <DialogActions sx={{ pr: "1.5rem", gap: "1rem" }}>
                        <ButtonComponent onClick={handleCancel}
                            type="button"
                            label={`${t("Login.cancel")}`}
                            variant="outlined"
                            sx={{
                                border: `1px solid ${theme.palette.text.defaultDark}`,
                                textTransform: "none",
                                borderRadius: "8px",
                                fontSize: "18px",
                                color: theme.palette.text.defaultDark,
                                // fontWeight: "600",
                                padding: "8px 14px",
                                "&:hover": {
                                    border: `1px solid ${theme.palette.text.defaultDark}`,
                                    backgroundColor: "#fff",
                                },
                            }}
                        />
                        <ButtonComponent
                            type="button"
                            label={`${t("Login.submit")}`}
                            onClick={handleSubmit}
                            sx={{
                                width: { xs: "80%", sm: "20%" },
                                background: theme.palette.background.btn,
                                color: theme.palette.text.primary,
                            }}
                        />

                    </DialogActions>
                </Box>
            </Dialog>
        </React.Fragment>
    );
}