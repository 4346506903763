// import { Box, Typography, useTheme } from "@mui/material";
// import React from "react";
// import time from "../../../../Assets/images/time.png";
// import elecIcon from "../../../../Assets/images/electric.png";
// import disIcon from "../../../../Assets/images/distance.png";
// import estricIcon from "../../../../Assets/images/estrik.png";

// const TruckCard = ({ data }) => {
//   const theme = useTheme();
//   return (
//     <Box
//       sx={{
//         // border: "solid red",
//         maxWidth: "330px",
//         width: "100%",
//         borderRadius: "9px",
//         position: "relative",
//         // background: theme.palette.background.section,
//         mt: "2rem !important",
//         gap:1,
//       }}
//     >
//       <Box sx={{ height: "220px", padding: 2 }}>
//         <img
//           src={data?.image}
//           alt=""
//           style={{ height: "100%", width: "100%", borderRadius: "4px" }}
//         />
//       </Box>
//       <Box sx={{ background: theme.palette.background.section, px: 3 }}>
//         <Typography
//           variant="h6"
//           sx={{
//             color: theme.palette.background.dark,
//             fontWeight: "700",
//           }}
//         >
//           {data.title}
//         </Typography>
//         <Typography
//           sx={{
//             color: theme.palette.text.time,
//             fontWeight: "500",
//           }}
//         >
//           {/* {data?.desc} */}
//           <img src={time} height={18} alt="" /> 8:00 AM to 10:00 PM
//         </Typography>
//         <Typography
//           sx={{
//             color: theme.palette.background.dark,
//             fontWeight: "500",
//           }}
//         >
//           {data?.desc}
//         </Typography>
//       </Box>
//       <Box
//         sx={{ display: "flex", gap: 1, justifyContent: "space-between", px: 2,fontSize:"15px" }}
//       >
//         <Box>
//           <img src={elecIcon} height={18} alt="" />{" "}
//           <span style={{ color: theme.palette.text.active }}>16A</span>{" "}
//           Electricity
//         </Box>
//         <Box>
//           <img src={disIcon} height={18} alt="" />{" "}
//           <span style={{ color: theme.palette.text.active }}>35</span> Meters
//         </Box>
//       </Box>
//           <Box sx={{ display: "flex", gap: 1, justifyContent: "start", px: 2,fontSize: "15px" }}>
//         <img src={estricIcon} height={18} alt="" />{" "}
//         <span style={{ color: theme.palette.text.blue }}>
//           Water & Electricity available
//         </span>
//       </Box>
//           <Box sx={{ display: "flex", gap: 1, justifyContent: "start", px: 2, fontSize: "15px" }}>
//         <span style={{ background: theme.palette.background.label,padding:5,borderRadius:'6px', }}>
//           Pizza
//         </span>
//       </Box>
//     </Box>
//   );
// };

// export default TruckCard;

import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import timeIcon from "../../../../Assets/images/time.png";
import elecIcon from "../../../../Assets/images/electric.png";
import disIcon from "../../../../Assets/images/distance.png";
import estricIcon from "../../../../Assets/images/estrik.png";
import ButtonComponent from "../../../../Component/Button/Button";

const TruckCard = ({ data }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        // maxWidth: { sm: 330 },
        width: "100%",
        borderRadius: 2,
        boxShadow: 3,
        position: "relative",
        mt: 2,
        display: "flex",
        flexDirection: "column",
      }}
    >
      {/* Image Section */}
      <Box sx={{ height: 220, overflow: "hidden" }}>
        <img
          src={data?.image}
          alt={data?.title || "Truck Image"}
          style={{
            height: "100%",
            width: "100%",
            objectFit: "cover",
            borderRadius: 2,
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
          }}
        />
      </Box>

      {/* Information Section */}
      <Box
        sx={{
          backgroundColor: theme.palette.background.section,
          px: 2,
          py: 1,
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        <Typography
          variant="h6"
          sx={{
            color: theme.palette.text.dark,
            fontWeight: 700,
          }}
        >
          {data.title}
        </Typography>
        <Typography
          sx={{
            color: theme.palette.text.time,
            fontWeight: 500,
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <img src={timeIcon} height={18} alt="Clock Icon" />
          {data?.hours || "8:00 AM to 10:00 PM"}
        </Typography>
        <Box sx={{ height: '100px', }}>
          <Typography
            sx={{
              color: theme.palette.text.dark,
              fontWeight: 500,
            }}
          >
            {data?.desc}
          </Typography>
        </Box>
      </Box>

      {/* Features Section */}
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: 'row', sm: "column", lg: 'row', },
          gap: 2,
          px: 2,
          backgroundColor: theme.palette.background.alternate,
          fontWeight: 500,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <img src={elecIcon} height={18} alt="Electricity Icon" />
          <Typography sx={{ color: theme.palette.text.active }}>16A</Typography>
          Electricity
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <img src={disIcon} height={18} alt="Distance Icon" />
          <Typography sx={{ color: theme.palette.text.active }}>
            35
          </Typography>{" "}
          Meters
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          //   flexDirection: "column",
          gap: 2,
          px: 2,
          py: 1,
          backgroundColor: theme.palette.background.alternate,
          fontWeight: 500,
        }}
      >
        <img src={estricIcon} height={18} alt="Water & Electricity Icon" />
        <Typography sx={{ color: theme.palette.text.blue }}>
          Water & Electricity available
        </Typography>
      </Box>

      {/* Category Badge */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          // py: 1,
          gap: 1,
          px: 2,
          pb: 2,
          flexWrap: "wrap",
          // backgroundColor: theme.palette.background.alternate,
        }}
      >
        {[
          "Pizza",
          "Burger",
          "Frenkie",
          "Pasta",
          "Burger",
          "Frenkie",
          "Pasta",
        ].map((x, i) => (
          <Typography
            sx={{
              backgroundColor: theme.palette.background.label,
              padding: 1,
              borderRadius: 1,
              color: theme.palette.text.label,
              fontWeight: 400,
            }}
          >
            {x}
          </Typography>
        ))}
      </Box>
      <Box className="flexCenter" sx={{ p: 2 }}>
        <ButtonComponent
          label={"Book Now"}
          sx={{
            width: "100%",
            background: theme.palette.background.btn,
            color: theme.palette.text.primary,
          }}
        />
      </Box>
    </Box>
  );
};

export default TruckCard;
