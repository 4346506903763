// import { Box, Typography, useTheme } from "@mui/material";
// import timeIcon from "../../../../Assets/images/time.png";
// import elecIcon from "../../../../Assets/images/electric.png";
// import disIcon from "../../../../Assets/images/distance.png";
// import estricIcon from "../../../../Assets/images/estrik.png";
// import ButtonComponent from "../../../../Component/Button/Button";

// const CatServiceCard = ({ data }) => {
//     const theme = useTheme();

//     return (
//         <Box
//             sx={{
//                 // maxWidth: 530,
//                 // width: "100%",
//                 boxShadow: 3,
//                 borderRadius: "8px",
//                 position: "relative",
//                 mt: 2,
//                 display: "flex",
//                 height: { xs: "", sm: "180px" },
//                 flexDirection: { xs: "column", sm: "row" },
//                 justifyContent: "start",
//                 alignItems: "start",
//             }}
//         >
//             {/ Image Section /}
//             <Box
//                 sx={{
//                     height: "100%",
//                     overflow: "hidden",
//                     width: { xs: "100%", sm: "120px" },
//                 }}
//             >
//                 <img
//                     src={data?.image}
//                     alt={data?.title || "Truck Image"}
//                     style={{
//                         height: "100%",
//                         width: "100%",
//                         objectFit: "cover",
//                         borderRadius: "8px",
//                         borderTopLeftRadius: "8px",
//                         borderBotttomLeftRadius: "8px",
//                     }}
//                 />
//             </Box>

//             {/ Information Section /}
//             <Box
//                 sx={{
//                     backgroundColor: theme.palette.background.main,
//                     px: 1,
//                     //   py: 1,
//                     display: "flex",
//                     flexDirection: "column",
//                     gap: 0.5,
//                     borderRadius: "8px",
//                 }}
//             >
//                 <Typography
//                     variant="h6"
//                     sx={{
//                         color: theme.palette.text.dark,
//                         fontWeight: 700,
//                     }}
//                 >
//                     {data.title}
//                 </Typography>

//                 <Typography
//                     sx={{
//                         color: theme.palette.text.darkOrange,
//                         fontWeight: 500,
//                     }}
//                 >
//                     Business caterer/ Barista
//                 </Typography>

//                 {/ Category Badge /}
//                 <Box
//                     sx={{
//                         display: "flex",
//                         justifyContent: "start",
//                         // py: 1,
//                         gap: 1,
//                         // px: 1,
//                         pb: 1,
//                         flexWrap: "wrap",
//                         // backgroundColor: theme.palette.background.alternate,
//                     }}
//                 >
//                     {["Pizza", "Burger", "Frenkie"].map((x, i) => (
//                         <Typography
//                             sx={{
//                                 backgroundColor: theme.palette.background.label,
//                                 padding: 1,
//                                 borderRadius: 1,
//                                 color: theme.palette.text.label,
//                                 fontWeight: 400,
//                                 fontSize: '14px'
//                             }}
//                         >
//                             {x}
//                         </Typography>
//                     ))}
//                 </Box>
//                 <Box className="flexStart">
//                     <ButtonComponent
//                         label={"Book Now"}
//                         sx={{
//                             background: theme.palette.background.btn,
//                             color: theme.palette.text.primary,
//                             fontSize: '14px'
//                         }}
//                     />
//                 </Box>
//             </Box>
//         </Box>
//     );
// };

// export default CatServiceCard;

import { Box, Typography, useTheme, Button, Grid } from "@mui/material";
import ButtonComponent from "../../../../Component/Button/Button";

const CatServiceCard = ({ data }) => {
  const theme = useTheme();

  return (
    // <Box
    //   sx={{
    //     boxShadow: 3,
    //     borderRadius: "8px",
    //     mt: 2,
    //     display: "flex",
    //     flexDirection: { xs: "column", sm: "row" },
    //     height: { xs: "auto", sm: "200px", md: '225px' },
    //     alignItems: "stretch",
    //     overflow: "hidden",
    //     backgroundColor: theme.palette.background.main,
    //     width: { xs: "100%", sm: "fit-content", md: 'auto' },
    //     m: 'auto'
    //   }}
    // >

    //   <Box
    //     sx={{
    //       width: { xs: "100%", sm: "150px", md: '130px', xl: "200px" },
    //       height: { xs: "200px", sm: "100%" },
    //       overflow: "hidden",
    //     }}
    //   >
    //     <img
    //       src={data?.image}
    //       alt={data?.title || "Catering Service Image"}
    //       style={{
    //         height: "100%",
    //         width: "100%",
    //         objectFit: "cover",
    //         borderRadius: "8px 0 0 8px",
    //       }}
    //     />
    //   </Box>


    //   <Box
    //     sx={{
    //       flex: 1,
    //       p: 2,
    //       px: { xs: 2, xl: 4 },
    //        display: "flex",
    //       flexDirection: "column",
    //       justifyContent: "space-between",
    //       backgroundColor: theme.palette.background.main,
    //     }}
    //   >
    //     <Box>
    //       <Typography
    //         variant="h6"
    //         sx={{
    //           color: theme.palette.text.dark,
    //           fontWeight: 700,
    //           mb: 1,
    //         }}
    //       >
    //         {data.title}
    //       </Typography>

    //       <Typography
    //         sx={{
    //           color: theme.palette.text.darkOrange,
    //           fontWeight: 500,
    //           mb: 1,
    //         }}
    //       >
    //         Business caterer / Barista
    //       </Typography>


    //       <Box
    //         sx={{
    //           display: "flex",
    //           flexWrap: "wrap",
    //           gap: 1,
    //         }}
    //       >
    //         {["Pizza", "Burger", "Frenkie"].map((category, i) => (
    //           <Typography
    //             key={i}
    //             sx={{
    //               backgroundColor: theme.palette.background.label,
    //               padding: "4px 8px",
    //               borderRadius: "8px",
    //               color: theme.palette.text.label,
    //               fontWeight: 400,
    //               fontSize: "14px",
    //             }}
    //           >
    //             {category}
    //           </Typography>
    //         ))}
    //       </Box>
    //     </Box>


    //     <Box
    //       sx={{
    //         mt: 2,
    //         pb: 2,
    //         display: "flex",
    //         justifyContent: "start",
    //       }}
    //     >
    //       <ButtonComponent
    //         label="Book Now"
    //         sx={{
    //           background: theme.palette.background.btn,
    //           color: theme.palette.text.primary,
    //           padding: "6px 16px",
    //           fontSize: "14px",
    //           borderRadius: "8px",
    //           "&:hover": {
    //             backgroundColor: theme.palette.background.btn,
    //           },
    //         }}
    //       />
    //     </Box>
    //   </Box>
    // </Box>
    <Grid
      container
      sx={{
        boxShadow: 3,
        borderRadius: "8px",
        mt: 2,
        flexDirection: { xs: "column", sm: "row" },
        height: { xs: "auto", sm: "auto", md: '225px' },
        overflow: "hidden",
        backgroundColor: theme.palette.background.main,
        width: { xs: "100%", sm: "100%", md: "100%" },
        maxWidth: { sm: "600px", md: "100%" },
        m: "auto",
      }}
    >
      {/* Image Section */}
      <Grid
        item
        xs={12}
        sm={12}
        md={4}
        sx={{
          width: { xs: "100%", sm: "150px", md: '130px', xl: "200px" },
          height: { xs: "200px", sm: "150px", md: '100%' },
          overflow: "hidden",
        }}
      >
        <img
          src={data?.image}
          alt={data?.title || "Catering Service Image"}
          style={{
            height: "100%",
            width: "100%",
            objectFit: "cover",
            borderRadius: { xs: "8px 8px 0 0", sm: "8px 0 0 8px" },
          }}
        />
      </Grid>

      {/* Content Section */}
      <Grid
        item
        xs={12}
        sm={12}
        md={8}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          p: { xs: 2, sm: 2, xl: 4 },
          backgroundColor: theme.palette.background.main,
        }}
      >
        {/* Title and Description */}
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: { xs: 'center', md: "space-between" }, alignItems: { xs: 'center', md: "start" } }}>
          <Typography
            variant="h6"
            sx={{
              color: theme.palette.text.dark,
              fontWeight: 700,
              mb: 1,
            }}
          >
            {data?.title}
          </Typography>

          <Typography
            sx={{
              color: theme.palette.text.darkOrange,
              fontWeight: 500,
              mb: 1,
            }}
          >
            Business caterer / Barista
          </Typography>

          {/* Categories */}
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
            {["Pizza", "Burger", "Frenkie"].map((category, i) => (
              <Typography
                key={i}
                sx={{
                  backgroundColor: theme.palette.background.label,
                  padding: "4px 8px",
                  borderRadius: "8px",
                  color: theme.palette.text.label,
                  fontWeight: 400,
                  fontSize: "14px",
                }}
              >
                {category}
              </Typography>
            ))}
          </Box>
        </Box>

        {/* Book Now Button */}
        <Box sx={{ mt: 2, pb: 2, display: "flex", justifyContent: { xs: 'center', md: "start" }, }}>
          <ButtonComponent
            label="Book Now"
            sx={{
              background: theme.palette.background.btn,
              color: theme.palette.text.primary,
              padding: "6px 16px",
              fontSize: "14px",
              borderRadius: "8px",
              "&:hover": {
                backgroundColor: theme.palette.background.btn,
              },
            }}
          />
        </Box>
      </Grid>
    </Grid>

  );
};

export default CatServiceCard;
