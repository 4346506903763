import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { ThemeProvider, useTheme } from "@mui/material";
import { theme } from "../Theme/Theme";
// import PrivateRoutes from "./PrivateRoutes";
import Login from "../Pages/Auth/Login/Login";
import Footer from "../Component/Footer/Footer";
import Navbar from "../Component/Navbar/Navbar";
import HomePage from "../Pages/HomePage/HomePage";
import Register from "../Pages/Auth/Register/Register";
import BecomeAPartnerScreen from "../Pages/BecomeAPartner/BecomeAPartnerScreen";
import PrivateRoutes from "./PrivateRoutes";

const AllRoutes = () => {
  const [themeMode, setThemeMode] = useState(
    localStorage.getItem("themeMode") ?? "light"
  );

  const {
    palette: { primary, mode, secondary },
  } = useTheme();

  return (
    <>
      <ThemeProvider theme={theme(themeMode)}>
        <Navbar themeMode={themeMode} changeTheme={(e) => setThemeMode(e)} />
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/" element={<HomePage />} />
          <Route path="/partner" element={<BecomeAPartnerScreen />} />
          <Route
            path="/*"
            element={
              <PrivateRoutes
                themeMode={themeMode}
                setThemeMode={setThemeMode}
              />
            }
          />{" "}
        </Routes>
        <Footer mode={mode} secondary={secondary} primary={primary} />
      </ThemeProvider>
    </>
  );
};

export default AllRoutes;
