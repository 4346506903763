// import { Box, TextField, useTheme, Radio, Typography } from "@mui/material";
// import { useFormik } from "formik";
// import { useTranslation } from "react-i18next";
// import * as Yup from "yup";
// import { StarSVG } from "../../../Component/SVG";
// import { useState } from "react";
// import ButtonComponent from "../../../Component/Button/Button";
// import EuroRoundedIcon from '@mui/icons-material/EuroRounded';

// const Step5 = () => {
//   const [selectedValue, setSelectedValue] = useState("");
//   const theme = useTheme();
//   const { t } = useTranslation();

//   const formik = useFormik({
//     initialValues: {
//       member: "", // Radio selection
//       discount: "", // Discount input field
//     },
//     validationSchema: Yup.object({
//       member: Yup.string().required(t("becomePartner.step5.required")),
//     }),
//     onSubmit: async (values) => {
//       console.log(values);
//     },
//   });

//   const handleRadioChange = (event) => {
//     setSelectedValue(event.target.value);
//     formik.setFieldValue("member", event.target.value); // Set radio value in Formik state
//   };

//   return (
//     <Box>
//       <Typography variant="h4" fontWeight={600} padding="1rem">
//         {t("becomePartner.step5.title")}
//       </Typography>

//       <Box
//         sx={{
//           background: theme.palette.background.main,
//           borderRadius: "25px",
//           padding: 3,
//           display: "flex",
//           flexDirection: "column",
//           gap: 2,
//         }}
//       >
//         {/* Member Selection */}
//         <Box>
//           <StarSVG /> {t("becomePartner.step5.member.title")}
//         </Box>

//         <Box
//           sx={{
//             display: "flex",
//             flexDirection: { xs: "column", sm: "row" },
//             gap: 1,
//           }}
//         >
//           {[
//             { item: "6", price: "66" },
//             { item: "12", price: "120" },
//           ].map((value) => (
//             <Box
//               key={value.item}
//               sx={{
//                 width: "100%",
//                 borderRadius: "9px",
//                 background: value.item === "6" ? "#FFF6ED" : "#F5F5F5",
//                 padding: "6px",
//                 display: "flex",
//                 justifyContent: "space-between",
//                 alignItems: "center",
//                 px: 2,
//               }}
//             >
//               <Box>
//                 <Radio
//                   onChange={handleRadioChange}
//                   checked={selectedValue === value.item}
//                   value={value.item}
//                   name="member"
//                   id={value.item}
//                 />
//                 <label htmlFor={value.item}>
//                   {t(`becomePartner.step5.member.${value.item}`)}
//                 </label>
//               </Box>
//               <Box sx={{ fontSize: '24px', color: theme.palette.text.darkOrange, fontWeight: "700", display: "flex", alignItems: "center" }}>{value.price}<EuroRoundedIcon /> </Box>
//             </Box>
//           ))}
//         </Box>
//         {formik.touched.member && formik.errors.member && (
//           <Typography color="error">{formik.errors.member}</Typography>
//         )}

//         {/* Discount Input */}
//         <Box
//           sx={{
//             display: "flex",
//             justifyContent: "center",
//             flexDirection: { xs: "column", sm: "row" },
//             gap: 1,
//           }}
//         >
//           <Box sx={{ width: { xs: "100%", md: "50%" } }}>
//             <Box
//               sx={{
//                 display: "flex",
//                 alignItems: "center",
//                 marginBottom: "8px",
//               }}
//             >
//               <StarSVG />{" "}
//               <Typography>
//                 {" "}
//                 {t("becomePartner.step5.discount.title")}
//               </Typography>
//             </Box>

//             <TextField
//               id="discount"
//               name="discount"
//               type="number"
//               {...formik.getFieldProps("discount")}
//               fullWidth
//               error={formik.touched.discount && Boolean(formik.errors.discount)}
//               helperText={formik.touched.discount && formik.errors.discount}
//               variant="outlined"
//               placeholder={t("becomePartner.step5.discount.pl")}
//               InputProps={{
//                 sx: {
//                   borderRadius: "12px",
//                   color: theme.palette.text.defaultDark,
//                   "&:hover": {
//                     bgcolor: theme.palette.background.inputBg,
//                   },
//                 },
//               }}
//             />
//           </Box>
//         </Box>

//         {/* Submit Button */}
//         <Box
//           sx={{
//             display: "flex",
//             justifyContent: "center",
//             marginTop: 2,
//           }}
//         >
//           <Box sx={{ pt: "0.5rem" }}>
//             <ButtonComponent
//               onClick={formik.handleSubmit}
//               label={`${t("becomePartner.step5.button")}`}
//               sx={{
//                 background: theme.palette.background.btn,
//                 color: theme.palette.text.primary,
//               }}
//             />
//           </Box>
//         </Box>
//       </Box>
//     </Box>
//   );
// };

// export default Step5;
import React, { useState, useEffect } from "react";
import { Box, TextField, useTheme, Radio, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { StarSVG } from "../../../Component/SVG";
import ButtonComponent from "../../../Component/Button/Button";
import EuroRoundedIcon from "@mui/icons-material/EuroRounded";

const Step5 = () => {
  const [selectedValue, setSelectedValue] = useState("");
  const [totalPrice, setTotalPrice] = useState(0);
  const theme = useTheme();
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: {
      member: "", // Radio selection
      discount: "", // Discount input field
    },
    validationSchema: Yup.object({
      member: Yup.string().required(t("becomePartner.step5.required")),
    }),
    onSubmit: async (values) => {
      console.log(values);
    },
  });

  // Define membership prices
  const membershipOptions = [
    { item: "6", price: 66 }, // Price for 6 months
    { item: "12", price: 120 }, // Price for 12 months
  ];

  // Function to handle price calculation
  const calculateTotalPrice = () => {
    const selectedOption = membershipOptions.find(
      (option) => option.item === selectedValue
    );
    const discountValue = formik.values.discount || 0;

    if (selectedOption)
    {
      const basePrice = selectedOption.price;
      // const finalPrice = basePrice - discountValue;
      const finalPrice = basePrice;

      setTotalPrice(finalPrice > 0 ? finalPrice : 0); // Ensure the price doesn't go negative
    }
  };

  // Recalculate the total price whenever the discount or selected membership changes
  useEffect(() => {
    calculateTotalPrice();
  }, [selectedValue, formik.values.discount]);

  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
    formik.setFieldValue("member", event.target.value); // Set radio value in Formik state
  };

  return (
    <Box>
      <Typography variant="h4" fontWeight={600} padding="1rem">
        {t("becomePartner.step5.title")}
      </Typography>

      <Box
        sx={{
          background: theme.palette.background.main,
          borderRadius: "25px",
          padding: 3,
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        {/* Member Selection */}
        <Box>
          <StarSVG /> {t("becomePartner.step5.member.title")}
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            gap: 2,
          }}
        >
          {membershipOptions.map((value) => (
            <Box
              key={value.item}
              sx={{
                width: "100%",
                borderRadius: "9px",
                background: value.item === "6" ? "#FFF6ED" : "#F5F5F5",
                padding: "6px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                px: 2,
              }}
            >
              <Box>
                <Radio
                  onChange={handleRadioChange}
                  checked={selectedValue === value.item}
                  value={value.item}
                  name="member"
                  id={value.item}
                />
                <label htmlFor={value.item}>
                  {t(`becomePartner.step5.member.${value.item}`)}
                </label>
              </Box>
              <Box
                sx={{
                  fontSize: "24px",
                  color: theme.palette.text.darkOrange,
                  fontWeight: "700",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {value.price}
                <EuroRoundedIcon />
              </Box>
            </Box>
          ))}
        </Box>
        {formik.touched.member && formik.errors.member && (
          <Typography color="error">{formik.errors.member}</Typography>
        )}

        {/* Discount Input */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: { xs: "column", sm: "row" },
          }}
        >
          <Box sx={{ width: { xs: "100%", md: "50%" } }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 0.5
                // marginBottom: "8px",
              }}
            >
              <StarSVG />
              <Typography>

                {t("becomePartner.step5.discount.title")}
              </Typography>
            </Box>

            <TextField
              id="discount"
              name="discount"
              type="number"
              {...formik.getFieldProps("discount")}
              fullWidth
              error={formik.touched.discount && Boolean(formik.errors.discount)}
              helperText={formik.touched.discount && formik.errors.discount}
              variant="outlined"
              placeholder={t("becomePartner.step5.discount.pl")}
              InputProps={{
                sx: {
                  borderRadius: "12px",
                  color: theme.palette.text.defaultDark,
                  "&:hover": {
                    bgcolor: theme.palette.background.inputBg,
                  },
                },
              }}
            />
          </Box>
        </Box>

        {/* Total Price Display */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: 1,
          }}
        >
          <Typography variant="h6" fontWeight={600}>
            {t("becomePartner.step5.total")} : {totalPrice}€
          </Typography>
        </Box>

        {/* Submit Button */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: 1,
          }}
        >
          <Box>
            <ButtonComponent
              onClick={formik.handleSubmit}
              label={`${t("becomePartner.step5.button")}`}
              sx={{
                background: theme.palette.background.btn,
                color: theme.palette.text.primary,
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Step5;
