export const MyBooking_EN_trans = {
  title: "My Booking",
  all: "All",
  pending: "Pending",
  cancelled: "Cancelled",
};

export const MyBooking_NL_trans = {
  title: "Mijn Boekingen",
  all: "Alle",
  pending: "In Behandeling",
  cancelled: "Geannuleerd",
};

