import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import logo from "../../Assets/images/logo.png";

import home from "../../Assets/images/Mobile/home.png";
import partner from "../../Assets/images/Mobile/partner.png";
import foodtruck from "../../Assets/images/Mobile/food-truck.png";
import about from "../../Assets/images/Mobile/about.png";
import chat from "../../Assets/images/Mobile/chat.png";
import reach from "../../Assets/images/Mobile/reachus.png";
import profile from "../../Assets/images/Mobile/user.png";
import login from "../../Assets/images/Mobile/login.png";

import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import { useTheme } from "@mui/material";
import { NavRoutes } from "./NavRoutes";
import { useTranslation } from "react-i18next";
import LocalizationDropDown from "./LocalizationDropDown";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

export default function TemporaryDrawer() {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const nav = useNavigate();
  const [loginID, setLoginID] = useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  useEffect(() => {
    const parseData = JSON.parse(localStorage.getItem("Fiesta"));
    const id = parseData?.id;
    if (id) {
      setLoginID(id);
    }

    const handleAuthUpdate = (event) => {
      const { isLoggedIn } = event.detail;
      if (isLoggedIn) {
        const updatedData = JSON.parse(localStorage.getItem("Fiesta"));
        setLoginID(updatedData?.id);
      } else {
        setLoginID(false);
      }
    };
    window.addEventListener("authUpdate", handleAuthUpdate);
    return () => {
      window.removeEventListener("authUpdate", handleAuthUpdate);
    };
  }, []);
  const MobileMenuItems = [
    { label: `${t("Navbar.Home")}`, path: NavRoutes.HOME, icon: home },
    {
      label: `${t("Navbar.BecomeAPartner")}`,
      path: NavRoutes.PARTNER,
      icon: partner,
    },
    {
      label: `${t("Navbar.FoodTrucks")}`,
      path: NavRoutes.TRUCKS,
      icon: foodtruck,
    },
    { label: `${t("Navbar.AboutUs")}`, path: NavRoutes.ABOUT, icon: about },
    { label: `${t("Navbar.chat")}`, path: NavRoutes.CHAT, icon: chat },
    { label: `${t("Navbar.ReachUs")}`, path: NavRoutes?.reach, icon: reach },
  ];

  if (loginID) {
    MobileMenuItems.push(
      {
        label: `${t("Navbar.Profile")}`,
        path: NavRoutes.PROFILE,
        icon: profile,
      },
      { label: `${t("Navbar.Logout")}`, path: NavRoutes.HOME, icon: login }
    );
  } else {
    MobileMenuItems.push({
      label: `${t("Navbar.Login")}`,
      path: NavRoutes.LOGIN,
      icon: login,
    });
  }

  const DrawerList = (
    <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
      <Box sx={{ width: "100%", py: 1 }} className="flexCenter">
        <img src={logo} alt="logo" height={80} width={120} />
      </Box>
      <List>
        {MobileMenuItems.map((text, index) => (
          <ListItem key={text.label} disablePadding>
            <ListItemButton onClick={() => nav(text.path)}>
              <ListItemIcon>
                <img height={24} width={24} src={text.icon} alt="preview" />
              </ListItemIcon>
              <ListItemText
                primary={text.label}
                style={{
                  color: theme.palette.text.defaultDark,
                  fontWeight: "500",
                }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          px: 1,
        }}
      >
        <LocalizationDropDown />
      </Box>
    </Box>
  );

  return (
    <div>
      <Button onClick={toggleDrawer(true)}>
        <MenuRoundedIcon style={{ fontWeight: "600" }} />
      </Button>

      <Drawer open={open} onClose={toggleDrawer(false)}>
        {DrawerList}
      </Drawer>
    </div>
  );
}
