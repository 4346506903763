import React from "react";
import { Box, Checkbox, useTheme, FormGroup, FormControlLabel } from "@mui/material";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { StarSVG } from "../../../Component/SVG";

const Step3 = () => {
    const theme = useTheme();
    const { t } = useTranslation();

    const formik = useFormik({
        initialValues: {
            selectedMeals: [], // Array to store selected meals
        },
        validationSchema: Yup.object({
            // Add your validation logic if needed
        }),
        onSubmit: (values) => {
            console.log("Selected Values:", values.selectedMeals);
            // Handle form submission
        },
    });

    const handleCheckboxChange = (value) => (event) => {
        const { checked } = event.target;
        const updatedArray = checked
            ? [...formik.values.selectedMeals, value]
            : formik.values.selectedMeals.filter((item) => item !== value);
        formik.setFieldValue("selectedMeals", updatedArray);
    };

    const renderCheckbox = (labelKey, value) => (
        <FormControlLabel
            control={
                <Checkbox
                    checked={formik.values.selectedMeals.includes(value)} // Check if value exists in array
                    onChange={handleCheckboxChange(value)}
                    sx={{
                        color: theme.palette.text.secondary,
                        "&.Mui-checked": {
                            color: theme.palette.text.active,
                        },
                    }}
                />
            }
            label={t(labelKey)}
        />
    );

    const renderMealCheckboxes = () => (
        <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" } ,gap:{md:4}}}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
                {[
                    { labelKey: "becomePartner.step3.meals.1", value: "meal1" },
                    { labelKey: "becomePartner.step3.meals.2", value: "meal2" },
                    { labelKey: "becomePartner.step3.meals.3", value: "meal3" },
                    { labelKey: "becomePartner.step3.meals.4", value: "meal4" },
                    { labelKey: "becomePartner.step3.meals.5", value: "meal5" },
                    { labelKey: "becomePartner.step3.meals.6", value: "meal6" },
                ].map(({ labelKey, value }) => renderCheckbox(labelKey, value))}
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column" }}>

                {[
                    { labelKey: "becomePartner.step3.meals.7", value: "meal7" },
                    { labelKey: "becomePartner.step3.meals.8", value: "meal8" },
                    { labelKey: "becomePartner.step3.meals.9", value: "meal9" },
                    { labelKey: "becomePartner.step3.meals.10", value: "meal10" },
                    { labelKey: "becomePartner.step3.meals.11", value: "meal11" },
                    { labelKey: "becomePartner.step3.meals.12", value: "meal12" },
                ].map(({ labelKey, value }) => renderCheckbox(labelKey, value))}
            </Box>

        </Box>
    );

    const renderDietCheckboxes = () => (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
            {[
                { labelKey: "becomePartner.step3.spDiet.1", value: "diet1" },
                { labelKey: "becomePartner.step3.spDiet.2", value: "diet2" },
                { labelKey: "becomePartner.step3.spDiet.3", value: "diet3" },
            ].map(({ labelKey, value }) => renderCheckbox(labelKey, value))}
        </Box>
    );

    return (
        <Box>
            <Box sx={{ fontSize: { xs: "24px" }, fontWeight: 600, padding: "1rem" }}>
                {t("becomePartner.step3.title")}
            </Box>

            <Box
                sx={{
                    background: theme.palette.background.main,
                    borderRadius: "25px",
                    padding: 3,
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                }}
            >
                <Box sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" }, gap: 1 }}>
                    {/* Meals Section */}
                    <Box sx={{ width: "100%" }}>
                        <Box py={1}>
                            <StarSVG /> {t("becomePartner.step3.meals.title")}
                        </Box>
                        <FormGroup row>{renderMealCheckboxes()}</FormGroup>
                    </Box>

                    {/* Special Diets Section */}
                    <Box sx={{ width: "100%" }}>
                        <Box py={1}>
                            <StarSVG /> {t("becomePartner.step3.spDiet.title")}
                        </Box>
                        <FormGroup row>{renderDietCheckboxes()}</FormGroup>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default Step3;
