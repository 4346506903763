import { Box, useTheme } from "@mui/material";
import React from "react";
import { Route, Routes } from "react-router-dom";
import HomePage from "../Pages/HomePage/HomePage";
import PrivateRoute from "./PrivateRouter";
import User from "../Pages/User/User";

const PrivateRoutes = ({ themeMode, setThemeMode }) => {
  const {
    palette: { secondary, primary, mode },
  } = useTheme();

  const routes = [
    { path: "/", component: <HomePage />, isPrivate: false },
    { path: "/profile", component: <User />, isPrivate: true },
  ];

  return (
    <Box>
      <Routes>
        {routes.map(({ path, component, isPrivate }) => (
          <Route
            key={path}
            path={path}
            element={
              isPrivate ? (
                <PrivateRoute>{component}</PrivateRoute>
              ) : (
                component
              )
            }
          />
        ))}
      </Routes>
    </Box>
  );
};

export default PrivateRoutes;
