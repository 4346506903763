export const CatService_EN_trans = {
    title: 'Explore our Catering Services',
    subtitle: 'It is a long established fact that a reader will be distracted by the readable',
    btn: 'View More'
}

export const CatService_NL_trans = {
    title: 'Ontdek onze Cateringdiensten',
    subtitle: 'Het is een algemeen bekend feit dat een lezer zal worden afgeleid door de leesbare inhoud',
    btn: 'Bekijk Meer'
}
