import { Box, Typography, useTheme } from "@mui/material";
import React from "react";
import bgImg from "../../../Assets/images/mainBG.webp";
import ButtonComponent from "../../../Component/Button/Button";
import { useTranslation } from "react-i18next";

const HomeSection = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    
    return (
        <Box
            sx={{
                height: "calc(100vh)",
                background: `url(${bgImg}) center center / cover`,
            }}
            className="flexCenter"
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    width: { xs: "100%", sm: "90%", md: "80%", lg: "60%" },
                    p: 3,
                    gap: 1.5,
                    maxWidth: "1400px",

                }}
            >
                <Box
                    sx={{
                        gap: { xs: "0", md: 2 },
                        width: { xs: "100%", sm: "70%", md: "95%", xl: '85%' },
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: { xs: "column", md: "row" },
                        px: 5,
                    }}
                >
                    <span
                        style={{
                            background: theme.palette.background.orange,
                            width: "100%",
                            height: "3px",
                        }}
                    ></span>

                    <Typography
                        variant="h6"
                        className="fontSnugle"
                        sx={{
                            color: theme.palette.text.active,
                            fontSize: { xs: "20px", md: "20px" },
                            width: { md: "700px" },
                            textAlign: "center",
                        }}
                    >
                        {t("section1.SubHeadline")}
                    </Typography>
                    <span
                        style={{
                            background: theme.palette.background.orange,
                            width: "100%",
                            height: "3px",
                        }}
                    ></span>
                </Box>

                <Typography
                    variant="h1"
                    className="fontSnugle"
                    sx={{
                        color: theme.palette.text.primary,
                        fontSize: { xs: "35px", sm: "55px", md: "69px", xl: "86px" },
                        textAlign: "center",
                    }}
                >
                    {t("section1.Headline")}
                </Typography>   
                <Box sx={{ pt: "0.5rem" }}>
                    <ButtonComponent
                        label={`${t("section1.button")}`}
                        sx={{
                            background: theme.palette.background.btn,
                            color: theme.palette.text.primary,
                        }}
                    />
                </Box>
            </Box>
        </Box >
    );
};

export default HomeSection;
