import { Box, Grid2, Typography, useTheme } from "@mui/material";
import React from "react";
import food_truck from "../../Assets/images/footer_truck.png";
import footer_logo from "../../Assets/images/footer_logo.png";
import food_bg_lines from "../../Assets/images/footer_bg_lines.png";
import mob_icon from "../../Assets/images/mob-Icon.png";
import mail_icon from "../../Assets/images/mail_icon.png";
import fb_icon from "../../Assets/images/fb_icon.png";
import insta_icon from "../../Assets/images/insta_icon.png";
import x_icon from "../../Assets/images/x_icon.png";
import wa_icon from "../../Assets/images/wa_icon.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        background: `radial-gradient(50% 50% at 50% 50%, #371F07 0%, #1A130C 100%)`,
        width: "100%",
        position: "relative",
        overflow: "hidden",

      }}
    >
      <Box sx={{ position: "absolute" }}>
        <img
          style={{ width: "100%", height: "100%" }}
          src={food_bg_lines}
          alt="preview"
        />
      </Box>
      <Box sx={{ width: "90%", marginX: "auto", py: 2, maxWidth: '1400px' }}>
        <Box
          sx={{
            width: "200px",
            height: "150px",
            position: "absolute",
            right: 0,
            bottom: 50,
          }}
        >
          <img
            style={{ width: "100%", height: "100%" }}
            src={food_truck}
            alt="preview"
          />
        </Box>
        <Box sx={{ zIndex: 1 }}>
          <Grid2
            spacing={2}
            container
            sx={{
              justifyContent: "space-between",
              paddingY: { xs: 2, md: 5 },
              height: "100%",

            }}
          >
            <Grid2 size={{ xs: 12, sm: 4 }}>
              <Box
                sx={{
                  width: { xs: "100%", md: "225px" },
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                  px: { xs: 1, md: 0 },
                  textAlign: { xs: "center", md: "start" },
                  alignItems: { xs: "center", md: "start" },

                }}
              >
                <Box sx={{ width: "160px", height: "105px" }}>
                  <img
                    style={{ width: "100%", height: "100%" }}
                    src={footer_logo}
                    alt="preview"
                  />
                </Box>
                <Typography sx={{ color: theme.palette.text.greyText }}>
                  {t("footer.content")}
                </Typography>
              </Box>
            </Grid2>
            <Grid2 size={{ xs: 12, sm: 4 }}>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                  textAlign: { xs: "center", md: "start" },
                  alignItems: { xs: "center", md: "center" },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                    alignItems: { xs: "center", md: "start" },
                  }}
                >
                  <Typography
                    sx={{
                      color: theme.palette.text.defaultLight,
                      fontWeight: 600,
                    }}
                  >
                    {t("footer.quick")}
                  </Typography>
                  <Link style={{ textDecoration: "none" }} to={"/"}>
                    {" "}
                    <Typography sx={{ color: theme.palette.text.greyText }}>
                      {t("footer.home")}
                    </Typography>
                  </Link>
                  <Typography
                    sx={{
                      color: theme.palette.text.greyText,
                      cursor: "pointer",
                    }}
                  >
                    {t("footer.register")}
                  </Typography>
                  <Typography
                    sx={{
                      color: theme.palette.text.greyText,
                      cursor: "pointer",
                    }}
                  >
                    {t("footer.faq")}
                  </Typography>
                  <Typography
                    sx={{
                      color: theme.palette.text.greyText,
                      cursor: "pointer",
                    }}
                  >
                    {t("footer.about")}
                  </Typography>
                  <Typography
                    sx={{
                      color: theme.palette.text.greyText,
                      cursor: "pointer",
                    }}
                  >
                    {t("footer.contact")}
                  </Typography>
                </Box>
              </Box>
            </Grid2>
            <Grid2 size={{ xs: 12, sm: 4 }}>
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                  textAlign: { xs: "center", md: "start" },
                  alignItems: { xs: "center", md: "end" },
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                  <Typography
                    sx={{
                      fontWeight: 600,
                      color: theme.palette.text.defaultLight,
                    }}
                  >
                    {t("footer.touch")}
                  </Typography>
                  <Box sx={{ display: "flex", gap: 2, cursor: "pointer" }}>
                    <img
                      style={{ width: "20px", height: "20px" }}
                      src={mob_icon}
                      alt="preview"
                    />
                    <Typography sx={{ color: theme.palette.text.defaultLight }}>
                      +42 4547 4524
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", gap: 2, cursor: "pointer" }}>
                    <img
                      style={{ width: "20px", height: "20px" }}
                      src={mail_icon}
                      alt="preview"
                    />
                    <Typography sx={{ color: theme.palette.text.defaultLight }}>
                      john@gmail.com
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid2>
          </Grid2>
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          bgcolor: theme.palette.background.strip,
          py: 2,
          gap: 1,
        }}
      >
        <Box
          sx={{
            width: "90%",
            marginX: "auto",
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: { xs: "center", md: "space-between" },
            alignItems: "center",
            gap: 1,
            maxWidth: '1400px'
          }}
        >
          <Box sx={{ display: "flex", gap: 2 }}>
            <Typography
              sx={{
                color: theme.palette.text.defaultLight,
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              {t("footer.privacy")}
            </Typography>
            <Typography
              sx={{
                color: theme.palette.text.defaultLight,
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              {t("footer.tc")}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", gap: 1 }}>
            <img
              style={{
                width: "35px", height: "35px",
                cursor: "pointer",
              }}
              src={fb_icon}
              alt="preview"
            />
            <img
              style={{ width: "35px", height: "35px", cursor: "pointer" }}
              src={x_icon}
              alt="preview"
            />
            <img
              style={{ width: "35px", height: "35px", cursor: "pointer" }}
              src={insta_icon}
              alt="preview"
            />
            <img
              style={{ width: "35px", height: "35px", cursor: "pointer" }}
              src={wa_icon}
              alt="preview"
            />
          </Box>
        </Box>{" "}
      </Box>
    </Box>
  );
};

export default Footer;
