// import React from "react";
// import { Box, Typography, useTheme } from "@mui/material";
// import { useTranslation } from "react-i18next";
// const MenuCard = ({ data }) => {
//   const theme = useTheme();
//   const { t } = useTranslation();

//   return (
//     <Box>
//       <Box
//         sx={{
//           position: "relative",
//           width: "240px",
//           borderRadius: "8px",
//           height: "180px",
//           display: "flex",
//           alignItems: "center",
//           justifyContent: "center",
//           overflow: "hidden",
//           "&::before": {
//             content: '""',
//             backgroundImage: `url(${data?.image})`,
//             backgroundSize: "cover",
//             backgroundPosition: "center",
//             position: "absolute",
//             top: 0,
//             right: 0,
//             bottom: 0,
//             left: 0,
//             // filter: "blur(1px)",
//           },
//         }}
//       >
//         <Typography
//           sx={{
//             color: theme.palette.text.defaultLight,
//             fontSize: {
//               xs: "18px",
//               sm: "20px",
//               md: "22px",
//             },
//             fontWeight: 600,
//             zIndex: 1,
//             width: "78%",
//             textAlign: "center",
//           }}
//         >
//           {data?.title}
//         </Typography>
//       </Box>
//     </Box>
//   );
// };

// export default MenuCard;

import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";

const MenuCard = ({ data }) => {
  const theme = useTheme();
  // const { t } = useTranslation();

  return (
    <Box>
      <Box
        sx={{
          position: "relative",
          width: "100%", // Full width inside the Grid item
          borderRadius: "8px",
          height: "180px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          overflow: "hidden",
          "&::before": {
            content: '""',
            backgroundImage: `url(${data?.image})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            position: "absolute",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            // filter: "blur(1px)",
          },
        }}
      >
        <Typography
          sx={{
            color: theme.palette.text.defaultLight,
            fontSize: {
              xs: "18px",
              sm: "20px",
              md: "22px",
            },
            fontWeight: 600,
            zIndex: 1,
            width: "78%",
            textAlign: "center",
          }}
        >
          {data?.title}
        </Typography>
      </Box>
    </Box>
  );
};

export default MenuCard;

