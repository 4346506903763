import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import upload from "../../Assets/images/drag.svg";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

export default function DragandDrop({
    setImage,
    setPreview,
    error,
    helperText,
    title,
    name,
    apiPreview,
}) {
    const [Preview, setPreviewimg] = useState();
    const { t } = useTranslation();
    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            "image/*": [],
        },
        onDrop: (acceptedFiles) => {
            const file = acceptedFiles[0];
            if (file)
            {
                if (file.size <= 5 * 1024 * 1024)
                {
                    const img = new Image();
                    img.onload = () => {
                        const width = img.width;
                        const height = img.height;
                        const aspectRatio = width / height;
                        // if (Math.abs(aspectRatio - 16 / 9) < 0.01) {
                        const url = URL.createObjectURL(file);
                        setPreviewimg(url);
                        // setImage(name || "cover", file);
                        URL.revokeObjectURL(img.src);
                        // } else {
                        //   toast.error("Please upload an image with a 16:9 aspect ratio.");
                        // }
                    };
                    img.src = URL.createObjectURL(file);
                } else
                {
                    toast.error("Selected file exceeds 5 MB limit.");
                }
            }
        },
    });

    return (
        <div>
            <Box
                sx={{
                    background: `#FFFAF5`,
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    justifyContent: "center",

                }}
                borderRadius="10px"
                border={error ? "1px dashed #d32f2f" : "1px dashed #F57C00"}
                p={2}
                m={"auto"}
                height={'220px'}
                width={"100%"}
                maxWidth={"540px"}
            >
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        justifyContent: "center",
                    }}
                    {...getRootProps()}
                >
                    <input {...getInputProps()} />
                    {apiPreview ? (
                        <div>
                            <figure>
                                <img
                                    src={apiPreview}
                                    alt="preview.."
                                    style={{
                                        width: "200px",
                                        height: "200px",
                                        objectFit: "contain",
                                        borderRadius: "10px",
                                    }}
                                />
                            </figure>
                        </div>
                    ) : Preview ? (
                        <>
                            <figure>
                                <img
                                    src={Preview}
                                    alt="preview.."
                                    style={{
                                        width: "200px",
                                        height: "200px",
                                        objectFit: "contain",
                                        borderRadius: "10px",
                                    }}
                                />
                            </figure>
                        </>
                    ) : (
                        <>
                            <figure>
                                <img
                                    src={upload}
                                    alt="logo.."
                                    width="80px"
                                    height="80px"
                                    draggable="false"
                                    style={{ objectFit: "contain", borderRadius: "10px" }}
                                />
                            </figure>
                            <Box
                                sx={{
                                    display: { xs: "grid", lg: "flex" },
                                    alignItems: "center",
                                    justifyContent: "center",
                                    placeItems: "center",
                                    ":hover": {
                                        opacity: "0.72",
                                    },
                                }}
                            >
                                <div style={{ textAlign: "center" }}>
                                    <Typography
                                        variant="h6"
                                        fontWeight="700"
                                        fontSize={"14px"}
                                    >
                                        {t("becomePartner.step2.foodTruckDetails.image.drag")}
                                    </Typography>
                                </div>
                            </Box>
                        </>
                    )}
                </div>
            </Box>
            <Typography ml={2} color="#d32f2f" fontSize="0.75rem">
                {helperText}
            </Typography>
        </div >
    );
}
