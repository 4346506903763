import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import profile_banner from "../../Assets/images/Profile/profile_banner.webp";

import { useTranslation } from "react-i18next";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import MyBookings from "./MyBookings";
import MyProfile from "./MyProfile";
const User = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Box>
      <Box sx={{ position: "relative", height: "300px" }}>
        <Box sx={{ height: "100%" }}>
          <img
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              filter: "blur(2px)",
              WebkitFilter: "blur(2px)",
            }}
            src={profile_banner}
            alt="preview"
          />{" "}
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              bgcolor: "rgba(0, 0, 0, 0.5)",
              borderRadius: "inherit",
            }}
          />
        </Box>
        <Box
          sx={{
            color: theme.palette.text.primary,
            position: "absolute",
            zIndex: 1,
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            fontSize: { xs: "24px", lg: "34px", md: "48px" },
          }}
        >
          <Typography
            sx={{
              color: theme.palette.text.primary,
              fontSize: { xs: "20px", lg: "34px", md: "48px" },
            }}
            className="fontSnugle"
          >
            FIESTA CATERING
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                textAlign: "center",
                color: theme.palette.text.primary,
                fontSize: { xs: "14px", lg: "24px", md: "28px" },
              }}
            >
              Home{" "}
            </Typography>
            <ArrowForwardIcon sx={{ color: theme.palette.text.primary }} />{" "}
            <Typography
              sx={{
                textAlign: "center",
                color: theme.palette.text.primary,
                fontSize: { xs: "14px", lg: "24px", md: "28px" },
              }}
            >
              {" "}
              Profile
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          background: `linear-gradient(180deg, #FFEDDD 0%, #F5F5F5 100%)`,
          display:"flex",
          flexDirection:"column",
          gap:3,
          justifyContent:"center",
          alignItems:"center",
        }}
      >
        <MyProfile />
        <MyBookings />
      </Box>
    </Box>
  );
};

export default User;
