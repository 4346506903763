import { Box, Typography, useTheme, Grid } from "@mui/material";
import React from "react";
import sideimg1 from "../../../Assets/images/strip1.png";
import sideimg2 from "../../../Assets/images/strip2.png";
import { useTranslation } from "react-i18next";
import TruckCard from "./Cards/TruckCard";
import Catering from "../../../Assets/images/cate1.png";
import Cat1 from "../../../Assets/images/cat2.jpg";
import Cat2 from "../../../Assets/images/cat3.jpg";
import ScrollAnimation from 'react-animate-on-scroll';
// import "animate.css/animate.min.css";

const cateringData = [
  {
    id: 1,
    title: "Corporate Catering",
    desc: "Providing professional catering services for corporate events with a variety of menu options.",
    image: Catering,
  },
  {
    id: 2,
    title: "Wedding Catering",
    desc: "Elegant catering services for weddings with customizable menus and themes to suit your special day.",
    image: Cat1,
  },
  {
    id: 3,
    title: "Private Catering",
    desc: "Exclusive catering for private parties with personalized food and drink menus to make your event memorable.",
    image: Cat2,
  },

];

const ExploreSection = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        width: "100%",
        background: theme.palette.background.section,
        position: "relative",
        flexDirection: "column",
      }}
    >

      {/* <Box sx={{ width: "90%", marginX: "auto", maxWidth: "1400px", }}>
        <Box
          sx={{
            width: { xs: "100%", sm: "80%", md: "50%", lg: "35%", xl: "30%" },
            p: 1,
            m: "auto",
          }}
        >

          <Typography
            variant="h3"
            className="fontSnugle"
            sx={{
              color: theme.palette.text.dark,
              fontSize: { xs: "28px", sm: "30px", md: "37px", xl: "40px" },
              textAlign: "center",
            }}
          >
            {t("explore.title")}
          </Typography>
          <Typography
            variant="h6"
            // className="fontSnugle"
            sx={{
              color: theme.palette.text.subHead,
              fontSize: { xs: "18px", md: "20px" },
              textAlign: "center",
              mt: "0.5rem",
            }}
          >
            {t("explore.subtitle")}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: { xs: "space-between", sm: 'center' },
            flexWrap: "wrap",
            gap: { xs: 2, md: 5 },
            p: 2,
            pb: 5,
          }}
        >
          {cateringData.map((x, i) => {
            // Conditional animations based on the index of the card
            let animationType;
            if (i === 0)
            {
              animationType = "animate__fadeInLeft";
            } else if (i === 1)
            {
              animationType = "animate__zoomIn";
            } else if (i === 2)
            {
              animationType = "animate__fadeInRight";
            }

            return (
              <ScrollAnimation
                animateIn={animationType}
                key={i}
              >
                <TruckCard data={x} key={i} />
              </ScrollAnimation>
            );
          })}

        </Box>

        <Box sx={{ position: "absolute", right: 0, top: "50px" }}>
          <img src={sideimg1} alt="" height={40} />
        </Box>
        <Box sx={{ position: "absolute", left: 0, bottom: "50px" }}>
          <img src={sideimg2} alt="" height={40} />
        </Box>
        <Box
          className="flexCenter"
          sx={{
            pb: 5,
          }}
        >
          <span
            style={{
              fontWeight: "600",
              color: theme.palette.text.active,
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            {" "}
            {t("catSer.btn")}
          </span>
        </Box>
      </Box> */}
      <Grid
        container
        spacing={3}
        sx={{ width: "90%", marginX: "auto", maxWidth: "1400px" }}
      >
        {/* Title Section */}
        <Grid item xs={12} className="removeDefault">
          <Grid
            container
            justifyContent="center"
            sx={{
              width: { xs: "100%", sm: "80%", md: "50%", lg: "35%", xl: "30%" },
              p: 1,
              m: "auto",
            }}
          >
            <Grid item xs={12}>
              <Typography
                variant="h3"
                className="fontSnugle"
                sx={{
                  color: theme.palette.text.dark,
                  fontSize: { xs: "28px", sm: "30px", md: "37px", xl: "40px" },
                  textAlign: "center",
                }}
              >
                {t("explore.title")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                sx={{
                  color: theme.palette.text.subHead,
                  fontSize: { xs: "18px", md: "20px" },
                  textAlign: "center",
                  mt: "0.5rem",
                }}
              >
                {t("explore.subtitle")}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        {/* Catering Data Section */}
        <Grid
          item
          container
          xs={12}
          justifyContent={{ xs: "space-between", sm: "center" }}
          spacing={3}
          sx={{ p: 2, pb: 5 }}
        >
          {cateringData.map((x, i) => {
            // Conditional animations based on the index of the card
            let animationType;
            if (i === 0)
            {
              animationType = "animate__fadeInLeft";
            } else if (i === 1)
            {
              animationType = "animate__zoomIn";
            } else if (i === 2)
            {
              animationType = "animate__fadeInRight";
            }

            return (
              <Grid item xs={12} sm={12} md={4} key={i}>
                <ScrollAnimation animateIn={animationType} key={i}>
                  <TruckCard data={x} key={i} />
                </ScrollAnimation>
              </Grid>
            );
          })}
        </Grid>

        {/* Side Images */}
        {/* <Grid item xs={12} sx={{ position: "relative" }}> */}
        <Box sx={{ position: "absolute", right: 0, top: "50px" }}>
          <img src={sideimg1} alt="" height={40} />
        </Box>
        <Box sx={{ position: "absolute", left: 0, bottom: "50px" }}>
          <img src={sideimg2} alt="" height={40} />
        </Box>
        {/* </Grid> */}

        {/* Button Section */}
        <Grid
          item
          xs={12}
          container
          justifyContent="center"
          sx={{ pb: 5 }}
        >
          <span
            style={{
              fontWeight: "600",
              color: theme.palette.text.active,
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            {t("catSer.btn")}
          </span>
        </Grid>
      </Grid>

    </Box>
  );
};

export default ExploreSection;
