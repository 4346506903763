export const footer_EN_trans = {
  content: `It is a long established fact that a reader will be distracted
                  by the readable It is a long.`,
  quick: " Quick Links",
  home: "Home",
  register: " Food Truck Registration",
  faq: "FAQs",
  about: " About us",
  contact: " Contact us",
  touch: "Get In Touch",
  privacy: "Privacy Policy",
  tc: "Terms & Condition",
};
export const footer_NL_trans = {
  content: `Het is een bekend feit dat een lezer afgeleid zal worden
                    door de leesbare tekst. Het is een lang verhaal.`,
  quick: "Snelle Links",
  home: "Home",
  register: "Food Truck Registratie",
  faq: "Veelgestelde Vragen",
  about: "Over Ons",
  contact: "Contacteer Ons",
  touch: "Neem Contact Op",
  privacy: "Privacybeleid",
  tc: "Algemene Voorwaarden",
};
