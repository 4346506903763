import React, { useState, useRef } from "react";
import { Box, useTheme, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import BecomeAPartnerStepper from "../../Component/Stepper/BecomeAPartnerStepper";
import Step1 from "./Step1/Step1";
import Step2 from "./Step2/Step2";
import Step3 from "./Step3/Step3";
import Step4 from "./Step4/Step4";
import Step5 from "./Step5/Step5";
import { toast } from "react-toastify";
import EastRoundedIcon from "@mui/icons-material/EastRounded";
import WestRoundedIcon from "@mui/icons-material/WestRounded";

const BecomeAPartnerScreen = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(1);
  const step1Ref = useRef(null);
  const step2Ref = useRef(null);
  const step3Ref = useRef(null);
  const step4Ref = useRef(null);
  const step5Ref = useRef(null);



const fetchStep1Data = async () => {
  const formik = step1Ref.current?.Formik;
  if (formik) {
    const savedData = JSON.parse(localStorage.getItem("Step1Data"));
    if (savedData) {
      formik.setValues(savedData);
    }
  }
};

const fetchStep2Data = async () => {
  const formik = step2Ref.current?.Formik;
  if (formik) {
    const savedData = JSON.parse(localStorage.getItem("Step2Data"));
    if (savedData) {
      formik.setValues(savedData);
    }
  }
};


const fetchStep3Data = async () => {
  const formik = step3Ref.current?.Formik;
  if (formik) {
    const savedData = JSON.parse(localStorage.getItem("Step3Data"));
    if (savedData) {
      formik.setValues(savedData);
    }
  }
};


const fetchStep4Data = async () => {
  const formik = step4Ref.current?.Formik;
  if (formik) {
    const savedData = JSON.parse(localStorage.getItem("Step4Data"));
    if (savedData) {
      formik.setValues(savedData);
    }
  }
};


const fetchStep5Data = async () => {
  const formik = step5Ref.current?.Formik;
  if (formik) {
    const savedData = JSON.parse(localStorage.getItem("Step5Data"));
    if (savedData) {
      formik.setValues(savedData);
    }
  }
};

  
  const handlePrev = async () => {
    switch (activeStep) {
      case 1:
        await fetchStep1Data();
        break;
      case 2:
        await fetchStep2Data();
        break;
      case 3:
        await fetchStep3Data();
        break;
      case 4:
        await fetchStep4Data();
        break;
      case 5:
        await fetchStep5Data();
        break;
      default:
        break;
    }
    setActiveStep((prev) => prev - 1);
  };
  const handleNext = async () => {
    switch (activeStep) {
      case 1:
        await handleStep1Logic();
        break;
      case 2:
        await handleStep2Logic();
        break;
      case 3:
        await handleStep3Logic();
        break;
      case 4:
        await handleStep4Logic();
        break;
      case 5:
        await handleStep5Logic();
        break;
      default:
        break;
    }
  };

  // const handleStep1Logic = async () => {
  //   if (activeStep === 1) {
  //     const formik = step1Ref.current?.Formik;
  //     console.log(formik,'formikkkk')
  //     await formik.submitForm();
  //     if (formik.isValid && Object.keys(formik.errors).length === 0) {
  //       console.log("Form is valid, proceeding to next step");
  //       setActiveStep((prev) => prev + 1);
  //     } else {
  //       console.log("Form validation failed", formik.errors);
  //     }
  //   }
  // };
  const handleStep1Logic = async () => {
    if (activeStep === 1)
    {
      const formik = step1Ref.current?.Formik;
      await formik.submitForm();
      if (formik.isValid && Object.keys(formik.errors).length === 0)
      {
        setActiveStep((prev) => prev + 1);
      } else
      {
        console.log("Form validation failed", formik.errors);
      }
    }
  };
  

  const handleStep2Logic = async () => {
    const formik = step2Ref.current?.formik;
    if (formik) {
      await formik.handleSubmit();
      if (formik.isValid) {
        setActiveStep((prev) => prev + 1);
      }
    }
  };

  const handleStep3Logic = async () => {
    const formik = step3Ref.current?.formik;
    if (formik) {
      await formik.handleSubmit();
      if (formik.isValid) {
        setActiveStep((prev) => prev + 1);
      }
    }
  };

  const handleStep4Logic = async () => {
    const formik = step4Ref.current?.formik;
    if (formik) {
      await formik.handleSubmit();
      if (formik.isValid) {
        setActiveStep((prev) => prev + 1);
      }
    }
  };

  const handleStep5Logic = async () => {
    const formik = step5Ref.current?.formik;
    if (formik) {
      await formik.handleSubmit();
      if (formik.isValid) {
        // Handle final submission or any other logic for Step 5
        console.log("Step 5 completed");
      }
    }
  };

  return (
    <Box
      sx={{
        background: theme.palette.background.section,
        width: "100%",
        minHeight: "calc(100vh - 390px)",
      }}
    >
      <Box
        sx={{
          width: "90%",
          m: "0 auto !important",
          py: 3,
          maxWidth: "1400px",
        }}
      >
        <Typography
          className="fontSnugle"
          sx={{
            color: theme.palette.text.darkOrange,
            fontSize: {
              xs: "28px",
              md: "24px",
              lg: "28px",
              xl: "35px",
            },
          }}
        >
          {activeStep !== 2
            ? t("becomePartner.title1")
            : t("becomePartner.title2")}
        </Typography>
        <BecomeAPartnerStepper activeStep={activeStep - 1} totalSteps={5} />
        <Box>
          {activeStep === 1 ? (
            <Step1 ref={step1Ref} />
          ) : activeStep === 2 ? (
            <Step2 />
          ) : activeStep === 3 ? (
            <Step3 />
          ) : activeStep === 4 ? (
            <Step4 />
          ) : (
            activeStep === 5 && <Step5 />
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: 1,
            p: 3,
          }}
        >
          <button
            className={activeStep !== 1 && "btnHover"}
            disabled={activeStep === 1}
            style={{
              background: "transparent",
              border: "none",
              cursor: activeStep !== 1 ? "pointer" : 'default',
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 1,
              // display: activeStep === 1 ? 'none' : 'block'
            }}
            onClick={() => handlePrev()}
          >
            <WestRoundedIcon /> {t("becomePartner.prev")}
          </button>

          <button
            className={activeStep !== 5 && "btnHover"}
            disabled={activeStep === 5}
            style={{
              background: "transparent",
              border: "none",
              cursor: activeStep !== 5 ? "pointer" : 'default',
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 1,
            }}
            onClick={handleNext}
          >
            {t("becomePartner.next")} <EastRoundedIcon />
          </button>
        </Box>
      </Box>
    </Box>
  );
};

export default BecomeAPartnerScreen;
