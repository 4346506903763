import { Box, Typography, useTheme } from "@mui/material";
import React from "react";
import catering from "../../../../Assets/images/catering.png";
// import cate1 from "../../../Assets/images/cate1.png";

const CateringCard = ({ data }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        // border: "solid red",
        // maxWidth: { sm: "330px" },
        width: "100%",
        borderRadius: "9px",
        position: "relative",
        background: theme.palette.background.section,
        mt: "2rem !important",
      }}
    >
      <Box
        className="flexCenter"
        position={"absolute"}
        sx={{ width: "100%", height: "100px" }}
        top={"-40px"}
      >
        <img
          src={catering}
          alt="catering"
          style={{ height: "100px", width: "100px" }}
        />
      </Box>
      <Box sx={{ height: "60px" }}></Box>

      <Box sx={{ background: theme.palette.background.section, padding: 2 }}>
        <Typography
          variant="h6"
          sx={{
            color: theme.palette.background.dark,
            fontWeight: "700",
            textAlign: "center",
          }}
        >
          {data.title}
        </Typography>
        <Box sx={{ height: '80px' }}>
          <Typography
            sx={{
              color: theme.palette.background.dark,
              fontWeight: "500",
              textAlign: "center",
            }}
          >
            {data?.desc}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ height: "240px", padding: 2 }}>
        <img
          src={data?.image}
          alt=""
          style={{ height: "100%", width: "100%", borderRadius: "4px" }}
        />
      </Box>
    </Box>
  );
};

export default CateringCard;
