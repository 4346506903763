export const Eventandcat_EN_trans = {
  title: "Event & Catering",
  subTitle:
    "It is a long established fact that a reader will be distracted by the readable",
  btn: "Find Food Truck",
  card:{
    title:'Food Truck Camper',
    content:`It is a long established fact that a reader will be distracted by the
          readable`
  }
};

export const Eventandcat_NL_trans = {
  title: "Evenement & Catering",
  subTitle:
    "Het is een algemeen bekend feit dat een lezer zal worden afgeleid door de leesbare inhoud",
  btn: "Vind een Food Truck",
  card: {
    title: "Food Truck Camper",
    content: `Het is een algemeen bekend feit dat een lezer zal worden afgeleid door de leesbare inhoud`,
  },
};

