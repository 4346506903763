export const Login_EN_Trans = {
  register: "Register",
  login: "Login",
  email: "Enter Email",
  password: "Enter Password",
  understand: "I Understand the",
  terms: "Terms of Service",
  and: "and",
  forgot: "Forgot Password?",
  privacy: " Privacy Policy.",
  already: "Already have an account?",
  donthave: "Don't have an account?",
  registerhere: "Register here",
  forgotnote: 'Lost your password? Please enter your email address. You will receive a link to create a new password via email.',
  cancel: 'Cancel',
  submit :"Submit"
};

export const Login_NL_Trans = {
  register: "Registreren",
  login: "Inloggen",
  email: "Voer e-mailadres in",
  password: "Voer wachtwoord in",
  understand: "Ik begrijp de",
  terms: "Gebruiksvoorwaarden",
  and: "en",
  forgot: "Wachtwoord vergeten?",
  privacy: "Privacyverklaring.",
  already: "Heb je al een account?",
  donthave: "Heb je nog geen account?",
  registerhere: "Registreer hier",
  forgotnote: '',
  cancel: ''
};

