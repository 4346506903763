import { Box, Typography, useTheme, Grid } from "@mui/material";
import React from "react";
// import sideimg1 from "../../../Assets/images/strip1.png";
import { useTranslation } from "react-i18next";
import burger from "../../../Assets/images/Menu_homepage/burger.png";
import pizza from "../../../Assets/images/Menu_homepage/Pizza.png";
import icecream from "../../../Assets/images/Menu_homepage/Icecream.png";
import bbq from "../../../Assets/images/Menu_homepage/bbq.png";
import MenuCard from "./Cards/MenuCard";
import ScrollAnimation from 'react-animate-on-scroll';


const MenuSection = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const cateringData = [
    {
      id: 1,
      title: t("Menu.burgermenu"),
      desc: "Providing professional catering services for corporate events with a variety of menu options.",
      image: burger,
    },
    {
      id: 2,
      title: t("Menu.pizzamenu"),
      desc: "Elegant catering services for weddings with customizable menus and themes to suit your special day.",
      image: pizza,
    },
    {
      id: 3,
      title: t("Menu.icecreammenu"),
      desc: "Exclusive catering for private parties with personalized food and drink menus to make your event memorable.",
      image: icecream,
    },
    {
      id: 4,
      title: t("Menu.bbqmenu"),
      desc: "Exclusive catering for private parties with personalized food and drink menus to make your event memorable.",
      image: bbq,
    },
  ];

  return (
    <Box
      sx={{
        width: "100%",
        background: theme.palette.background.section,
        position: "relative",
        flexDirection: "column",
        paddingY: 5,
        gap: 5,
      }}
    >
      <Box
        sx={{
          width: { xs: "90%", sm: "80%", md: "50%", lg: "50%" },
          m: "auto",
        }}
      >
        <Typography
          variant="h3"
          className="fontSnugle"
          sx={{

            color: theme.palette.text.dark,
            fontSize: { xs: "28px", sm: "30px", md: "37px", xl: "40px" },
            textAlign: "center",
          }}
        >
          {t("Menu.menutitle")}
        </Typography>
        <Typography
          variant="h6"
          // className="fontSnugle"
          sx={{
            color: theme.palette.text.subHead,
            fontSize: { xs: "18px", md: "20px" },
            textAlign: "center",
            mt: "0.5rem",
          }}
        >
          {t("Menu.subtitle")}
        </Typography>
      </Box>

      {/* <Box sx={{ width: "90%", marginX: "auto" }} >
        <Box
          sx={{
            display: "flex",
            justifyContent: { xs: "space-between", sm: 'center' },
            // justifyContent: 'space-between',
            alignItems: "center",
            flexDirection: { xs: "column", sm: "row" },
            flexWrap: "wrap",
            gap: 2,
            mt: 2,
          }}
        >
          {cateringData.map((x, i) => {
            const reverseIndex = cateringData.length - 1 - i; 
            const delay = reverseIndex * 200; 

            return (
              <ScrollAnimation
                animateIn="animate__slideInLeft" 
                delay={delay}
                key={i}
              >
                <MenuCard data={x} />
              </ScrollAnimation>
            );
          })}

        </Box>
      </Box> */}

      <Box sx={{ width: { xs: "90%", xl: "80%" }, marginX: "auto" }}>
        <Grid container spacing={2} sx={{ mt: 2 }} justifyContent={'center'} alignItems={'center'} >
          {cateringData.map((x, i) => {
            const reverseIndex = cateringData.length - 1 - i;
            const delay = reverseIndex * 200; // Adjusting delay for animation

            return (
              <Grid item xs={12} sm={6} md={3} lg={3} xl={2.5} key={i}>
                <ScrollAnimation animateIn="animate__slideInLeft" delay={delay}>
                  <MenuCard data={x} />
                </ScrollAnimation>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Box>
  );
};

export default MenuSection;
