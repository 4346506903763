import { BrowserRouter } from 'react-router-dom';
import './App.css';
import AllRoutes from './Routes/AllRoutes';
import "animate.css/animate.min.css";

function App() {
  return (
    <BrowserRouter>
      <AllRoutes />
    </BrowserRouter>
  );
}

export default App;
