export const Search_En_trans = {
    validate: {
        food: 'Food is required',
        codeType: 'Must be a number',
        code: 'Zip code is required',
        veg: 'At least one option is required'
    },
    input1: 'Search for the food',
    input2: 'Enter your zip code',
    input3: 'Vegetarian',
    input4: 'Vegan',
    input5: 'Gluten-Free',
    btn: 'Search Now'
}

export const Search_Nl_trans = {
    validate: {
        food: 'Voedsel is verplicht',
        codeType: 'Moet een getal zijn',
        code: 'Postcode is verplicht',
        veg: 'Minstens één optie is verplicht'
    },
    input1: 'Zoek naar voedsel',
    input2: 'Voer uw postcode in',
    input3: 'Vegetarisch',
    input4: 'Veganistisch',
    input5: 'Glutenvrij',
    btn: 'Nu zoeken'
}

