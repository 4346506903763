import {
  Box,
  TextField,
  Typography,
  useTheme,
  IconButton,
  InputAdornment,
} from "@mui/material";
import React, { useState } from "react";
import login_bg_truck from "../../../Assets/images/login_bg_truck.png";
import login_bg_lines from "../../../Assets/images/login_bg_lines.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import ButtonComponent from "../../../Component/Button/Button";
import { useTranslation } from "react-i18next";
import { API_POST } from "../../../API/config";
import { toast } from "react-toastify";
import { API_endpoints } from "../../../API/API_Endpoints";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import ForgotPasswordDialogue from "./ForgotPassword";
const Login = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .matches(emailRegex, "Invalid email")
        .required("Required"),
      password: Yup.string()
        .required("Required")
        .min(6, "Required at least 6 digits.")
        .max(20, "Maximum 20 digits allowed"),
    }),
    onSubmit: async (values) => {
      try
      {
        const res = await API_POST(API_endpoints.login, values);
        if (res.s)
        {
          toast.success(res.m);
          const serializedData = JSON.stringify(res.r);
          localStorage.setItem("Fiesta", serializedData);
          const event = new CustomEvent("authUpdate", {
            detail: { isLoggedIn: true },
          });
          window.dispatchEvent(event);
          navigate("/");
        } else
        {
          toast.error(res.m);
        }
      } catch (error)
      {
        console.error("Error:", error);
      }
    },
  });
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  
  return (
    <Box
      sx={{
        background: `linear-gradient(180deg, #FFEDDD 0%, #F5F5F5 100%)`,
        height: { xs: "calc(100vh - 250px)", md: "calc(100vh - 66px)" },
        overflow: "hidden",
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box sx={{ position: "absolute", bottom: -5, left: 0 }}>
        <img
          style={{ width: "150px", height: "150px" }}
          src={login_bg_truck}
          alt="preview"
        />
      </Box>
      <Box sx={{ position: "absolute", top: 50, right: 0 }}>
        <img
          style={{ width: "120px", height: "35px" }}
          src={login_bg_lines}
          alt="preview"
        />
      </Box>
      {/* main content */}
      <Box
        sx={{

          width: { xs: "100%", sm: '90%', md: "70%" },
          // height: "calc(100vh - 66px)",
          zIndex: 1,
          textAlign: "center",
          display: "flex",
          gap: 1,
          flexDirection: "column",
          p: 2,
        }}
      >
        <Typography
          className="fontSnugle"
          sx={{ fontSize: { xs: "24px", md: "26px", lg: "30px" } }}
        >
          {t("Login.login")}
        </Typography>
        <Box
          sx={{
            width: "100%",
            flexGrow: 1,
            bgcolor: theme.palette.background.main,
            borderRadius: "20px",
            p: { xs: 0.5, sm: 2 },

          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
              padding: "5%",
              pb: { xs: 2, md: 1 },
            }}
          >
            <TextField
              type="email"
              id="email"
              name="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              variant="outlined"
              placeholder={t("Login.email")}
              InputProps={{
                sx: {
                  borderRadius: "12px",
                  color: theme.palette.text.defaultDark,
                  "&:hover": {
                    color: theme.palette.text.active,
                    bgcolor: theme.palette.background.inputBg,
                    borderRadius: "12px",
                  },
                },
              }}
            />
            <TextField
              id="password"
              name="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              type={showPassword ? "text" : "password"} // Toggles between plain text and password
              InputProps={{
                sx: {
                  borderRadius: "12px",
                  color: theme.palette.text.defaultDark,
                  "&:hover": {
                    color: theme.palette.text.active,
                    bgcolor: theme.palette.background.inputBg,
                    borderRadius: "12px",
                  },
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                    >
                      {showPassword ? (
                        <VisibilityOff
                          sx={{ color: theme.palette.text.active }}
                        />
                      ) : (
                        <Visibility sx={{ color: theme.palette.text.active }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              placeholder={t("Login.password")}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              justifyContent: "space-between",
              alignItems: "center",
              px: "5%",
              gap: 1,
            }}
          >
            <Box sx={{ flex: 1 }}></Box>
            <Box sx={{ display: { xs: "none", sm: "block" } }}>

              <ForgotPasswordDialogue />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              paddingTop: 1,
              gap: 1.5,
              pb: 2,
            }}
          >
            <ButtonComponent
              type="button"
              label={`${t("Navbar.Login")}`}
              onClick={formik.handleSubmit}
              sx={{
                width: { xs: "80%", sm: "20%" },
                background: theme.palette.background.btn,
                color: theme.palette.text.primary,
              }}
            />
            <Box sx={{ display: { xs: "block", sm: "none" } }}>

              <ForgotPasswordDialogue />
            </Box>
            <Typography
              sx={{
                color: theme.palette.text.defaultDark,
                fontSize: { xs: "12px", md: "14px" },
              }}
            >
              {t("Login.donthave")}
              &nbsp;
              <Link
                to="/register"
                style={{
                  color: theme.palette.text.active,
                  textDecoration: "underline",
                }}
              >
                {t("Login.registerhere")}
              </Link>{" "}

            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Login;
