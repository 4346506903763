export const About_En_trans = {
  title: "About Fiesta Catering",
  subtitle:
    "It is a long established fact that a reader will be distracted by the readable It is a long established fact that a reader will be distracted by the readable",
  list: " It is a long established fact that a reader will be.",
  button: "Find Food Truck",
};
export const About_NL_trans = {
  title: "Over Fiesta Catering",
  subtitle:
    "Het is een bekend feit dat een lezer afgeleid zal worden door de leesbare tekst. Het is een bekend feit dat een lezer afgeleid zal worden door de leesbare tekst.",
  list: "Het is een bekend feit dat een lezer afgeleid zal worden.",
  button: "Vind een Food Truck",
};
