import {
  Box,
  TextField,
  useTheme,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid
} from "@mui/material";
import { useFormik } from "formik";
import React from "react";
import * as Yup from "yup";
import ButtonComponent from "../../../Component/Button/Button";
import { useTranslation } from "react-i18next";

const SearchSection = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  // Formik setup with Yup validation schema
  const formik = useFormik({
    initialValues: {
      food: "",
      zipCode: "",
      veg: [],
    },
    validationSchema: Yup.object({
      food: Yup.string().required(`${t("search.validate.food")}`),
      zipCode: Yup.number()
        .typeError(`${t("search.validate.codeType")}`)
        .required(`${t("search.validate.code")}`),
      veg: Yup.array().min(1, `${t("search.validate.veg")}`),
    }),
    onSubmit: (values) => {
      console.log("Form submitted:", values);
    },
  });

  // Handle checkbox change for veg options
  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    const veg = formik.values.veg;

    if (checked)
    {
      formik.setFieldValue("veg", [...veg, value]);
    } else
    {
      formik.setFieldValue(
        "veg",
        veg.filter((item) => item !== value)
      );
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        background: theme.palette.background.section,
        py: 3,
      }}
    >
      {/* <Box
        sx={{
          width: "90%",
          maxWidth: "1400px",
          marginX: "auto",
          boxShadow: "0px 0px 20px 0px #17120D14",
          borderRadius: "14px",
          padding: 3,
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          gap: 1.5,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1.5,
            width: { xs: "100%", md: "88%" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: 2,
              width: { xs: "100%", md: "80%" },
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            <TextField
              placeholder={t("search.input1")}
              value={formik.values.food}
              type="text"
              name="food"
              fullWidth
              variant="outlined"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.food && Boolean(formik.errors.food)}
              helperText={formik.touched.food && formik.errors.food}
              InputProps={{
                sx: {
                  color: theme.palette.text.defaultDark,
                  "&:hover": {
                    color: theme.palette.text.active,
                    bgcolor: theme.palette.background.inputBg,
                    borderRadius: "8px",
                  },
                },
              }}
            />
            <TextField
              placeholder={t("search.input2")}
              value={formik.values.zipCode}
              type="text"
              name="zipCode"
              fullWidth
              variant="outlined"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.zipCode && Boolean(formik.errors.zipCode)}
              helperText={formik.touched.zipCode && formik.errors.zipCode}
              InputProps={{
                sx: {
                  color: theme.palette.text.defaultDark,
                  "&:hover": {
                    color: theme.palette.text.active,
                    bgcolor: theme.palette.background.inputBg,
                    borderRadius: "8px",
                  },
                },
              }}
            />
          </Box>

          <FormGroup row sx={{ gap: 1 }}>
            {[
              `${t("search.input3")}`,
              `${t("search.input4")}`,
              `${t("search.input5")}`,
            ].map((label) => (
              <FormControlLabel
                key={label}
                control={
                  <Checkbox
                    id={label}
                    value={label}
                    checked={formik.values.veg.includes(label)}
                    onChange={handleCheckboxChange}
                  />
                }
                label={label}
                sx={{ color: theme.palette.text.secondary }}
              />
            ))}
          </FormGroup>

          {formik.touched.veg && formik.errors.veg && (
            <Box sx={{ color: "#E06B6B", fontSize: "12px" }}>
              {formik.errors.veg}
            </Box>
          )}
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: { xs: "center", md: "start" },
          }}
        >
          <ButtonComponent
            label={t("search.btn")}
            sx={{
              background: theme.palette.background.btn,
              color: theme.palette.text.primary,
              width: "180px",
            }}
            onClick={formik.handleSubmit}
          />
        </Box>
      </Box> */}
      <Grid
        container
        spacing={3}
        sx={{
          width: "90%",
          maxWidth: "1400px",
          marginX: "auto",
          boxShadow: "0px 0px 20px 0px #17120D14",
          borderRadius: "14px",
          padding: 3,
          my: 3
        }}
      >
        {/* Left section */}
        <Grid className="removeDefault" item xs={12} md={9}>
          <Grid container spacing={2}>
            {/* Food search input */}
            <Grid item xs={12} md={6}>
              <TextField
                placeholder={t("search.input1")}
                value={formik.values.food}
                type="text"
                name="food"
                fullWidth
                variant="outlined"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.food && Boolean(formik.errors.food)}
                helperText={formik.touched.food && formik.errors.food}
                InputProps={{
                  sx: {
                    color: theme.palette.text.defaultDark,
                    "&:hover": {
                      color: theme.palette.text.active,
                      bgcolor: theme.palette.background.inputBg,
                      borderRadius: "8px",
                    },
                  },
                }}
              />
            </Grid>

            {/* Zip code input */}
            <Grid item xs={12} md={6}>
              <TextField
                placeholder={t("search.input2")}
                value={formik.values.zipCode}
                type="text"
                name="zipCode"
                fullWidth
                variant="outlined"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.zipCode && Boolean(formik.errors.zipCode)}
                helperText={formik.touched.zipCode && formik.errors.zipCode}
                InputProps={{
                  sx: {
                    color: theme.palette.text.defaultDark,
                    "&:hover": {
                      color: theme.palette.text.active,
                      bgcolor: theme.palette.background.inputBg,
                      borderRadius: "8px",
                    },
                  },
                }}
              />
            </Grid>
          </Grid>

          {/* Checkbox section for veg options */}
          <Grid item xs={12}>
            <FormGroup row sx={{ gap: 1 }}>
              {[t("search.input3"), t("search.input4"), t("search.input5")].map(
                (label) => (
                  <FormControlLabel
                    key={label}
                    control={
                      <Checkbox
                        id={label}
                        value={label}
                        checked={formik.values.veg.includes(label)}
                        onChange={handleCheckboxChange}
                      />
                    }
                    label={label}
                    sx={{ color: theme.palette.text.secondary }}
                  />
                )
              )}
            </FormGroup>

            {/* Error message for veg */}
            {formik.touched.veg && formik.errors.veg && (
              <Box sx={{ color: "#E06B6B", fontSize: "12px" }}>
                {formik.errors.veg}
              </Box>
            )}
          </Grid>
        </Grid>

        {/* Submit button section */}
        <Grid item xs={12} md={3} className="removeDefault" container justifyContent={{ xs: "center", md: "end" }} alignItems={"start"} >
          <ButtonComponent
            label={t("search.btn")}
            sx={{
              background: theme.palette.background.btn,
              color: theme.palette.text.primary,
              width: "180px",
            }}
            onClick={formik.handleSubmit}
          />
        </Grid>
      </Grid>

    </Box>
  );
};

export default SearchSection;
