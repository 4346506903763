export const Home_EN_Trans = {
    Headline: 'Food Trucking Service Catering & Event',
    SubHeadline: 'RENT YOUR FOOD TRUCK',
    button: 'Make a request?'
}

export const Home_NL_Trans = {
    Headline: 'Foodtruck-service voor catering en evenementen',
    SubHeadline: 'HUUR JE EIGEN FOODTRUCK',
    button: 'Verzoek indienen?'
}
