import React from "react";
import { Box, TextField, useTheme } from "@mui/material";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { StarSVG } from "../../../Component/SVG";
import MultiImageUpload from "../../../Component/ImageUploader/MultiImageUploader";

const Step4 = () => {
    const theme = useTheme();
    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
        },
        validationSchema: Yup.object({
            email: Yup.string()
                // .matches(emailRegex, "Invalid email")
                .required("Required"),
            password: Yup.string()
                .required("Required")
                .min(6, "Required at least 6 digits.")
                .max(20, "Maximum 20 digits allowed"),
        }),
        onSubmit: async (values) => {
            //   try {
            //     const res = await API_POST(API_endpoints.login, values);
            //     if (res.s) {
            //       toast.success(res.m);
            //       navigate("/");
            //     } else {
            //       toast.error(res.m);
            //     }
            //   } catch (error) {
            //     console.error("Error:", error);
            //   }
        },
    });
    const { t } = useTranslation();
    return (
        <Box>
            <Box
                sx={{
                    fontSize: { xs: "25px", fontWeight: "600", padding: "1rem" },
                }}
            >
                {t("becomePartner.step4.title")}
            </Box>

            <Box
                sx={{
                    background: theme.palette.background.main,
                    borderRadius: "25px",
                    padding: 3,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    gap: 2,
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: { xs: "column", sm: "row" },
                        gap: 1,
                    }}
                >
                    <Box sx={{ width: "100%", }}>
                        <Box py={1}>
                            <Box>
                                <StarSVG /> {t("becomePartner.step4.image.title")}
                            </Box>
                            <Box
                                sx={{
                                    pl: "1.35rem",
                                    color: theme.palette.text.subHead,
                                    my: 0.5,
                                    fontSize: "15px",
                                }}
                            >
                                {t("becomePartner.step4.image.note")}
                            </Box>
                        </Box>
                        <MultiImageUpload imgTitle={false} max={3} />
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: { xs: "column", sm: "row" },
                        gap: 1,
                    }}
                >
                    <Box sx={{ width: "100%" }}>
                        <Box py={1}>
                            <Box>
                                <StarSVG /> {t("becomePartner.step4.certi.title")}
                            </Box>
                            <Box
                                sx={{
                                    pl: "1.35rem",
                                    color: theme.palette.text.subHead,
                                    my: 0.5,
                                    fontSize: "15px",
                                }}
                            >
                                {t("becomePartner.step4.certi.note")}
                            </Box>        </Box>

                        <MultiImageUpload imgTitle={true} max={5} />

                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default Step4;
